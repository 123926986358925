import React from "react";
import NavBar from "../../Layout/nav-bar";
import FooterComponent from "../../Layout/footer";

const MyOrders = () => {
  return (
    <div>
      <NavBar />
      <div className="main-container main-container-height text-left">
        <p className=" page-header">My Orders</p>
        <div style={{ height: "30vh" }}>
          <p>You haven't placed any orders yet</p>
        </div>
      </div>

      <FooterComponent />
    </div>
  );
};

export default MyOrders;
