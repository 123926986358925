import React from "react";

const FreeShipping = () => {
  return (
    <div style={{ background: "#31d1b1" }} className="py-1">
      <p className="m-0">Free Shipping in Australia Orders Over $100</p>
    </div>
  );
};

export default FreeShipping;
