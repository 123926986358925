import { useNavigate, useParams } from "react-router-dom";
import { useApplicationContext } from "../../Context/app-context";
import NavBar from "../../Layout/nav-bar";
import { useEffect, useState } from "react";
import FooterComponent from "../../Layout/footer";
import AddToCart from "../Cart/add-to-cart";
import AddToWishlist from "../Wishlist/add-to-wishlist";
import BreadcrumbComponent from "../../Utils/breadcrumb";
import "react-medium-image-zoom/dist/styles.css";
import { MetaTags, trimDescription } from "../../Utils/helperFunction";
// import AddToCart from "../Cart/add-to-cart";
// import CheckoutComponent from "../../Payment/checkout";
import ReactImageMagnify from "react-image-magnify";
const ProductPage = () => {
  //eslint-disable-next-line
  const { allProducts, getConvertedPrice, currencySymbol, isProductsLoading } =
    useApplicationContext();
  const [productData, setProductData] = useState([]);
  //eslint-disable-next-line
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const [imageArr, setImageArr] = useState([]);
  const { productName } = useParams();

  const navigate = useNavigate();

  // Retrieve individual parameters

  useEffect(() => {
    const products = allProducts.filter(
      (product) => product.transformed_name === productName
    );

    if (products.length === 0) return;
    setProductData(products);
    setImageArr(products[0].imageURLs);
    setImagePreview(products[0].imageURLs[0]);

    //eslint-disable-next-line
  }, [allProducts]);

  return (
    <div>
      <MetaTags
        title={productData[0]?.name || "Risheet Product"}
        description={productData[0]?.description || ""}
        robots="index,follow"
        schema={`
    {
      "@context": "http://schema.org",
      "@type": "Product",
      "name": "${productData[0]?.name || "Risheet Product"}",
      "image": "${
        productData[0]?.imageURLs ? productData[0].imageURLs[0] : ""
      }",
      "description": "${productData[0]?.description || ""}",
      "brand": {
        "@type": "Brand",
        "name": "Risheet",
        "logo": "https://www.risheet.com/static/media/logo.0f949a89208bd9a53b3e.png"
      },
      "offers": {
        "@type": "Offer",
        "price": "${productData[0]?.price ? productData[0]?.price : "0.00"}",
        "priceCurrency": "AUD", 
        "availability": "${
          productData[0]?.availability || "http://schema.org/InStock"
        }",
        "hasMerchantReturnPolicy": {
          "@type": "MerchantReturnPolicy",
          "url": "https://www.risheet.com/return-policy"
        },
        "shippingDetails": {
          "@type": "OfferShippingDetails",
          "shippingRate": {
            "@type": "MonetaryAmount",
            "value": "0.00",
            "currency": "AUD" 
          },
          "deliveryTime": {
            "@type": "ShippingDeliveryTime",
            "businessDays": {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "http://schema.org/Monday",
                "http://schema.org/Tuesday",
                "http://schema.org/Wednesday",
                "http://schema.org/Thursday",
                "http://schema.org/Friday"
              ],
              "opens": "09:00",
              "closes": "17:00"
            }
          },
          "shippingDestination": {
            "@type": "DefinedRegion",
            "addressCountry": "AU"
          }
        }
      },
      "color": "${productData[0]?.colour || ""}",
      "review": [
        {
          "@type": "Review",
          "author": {
            "@type": "Person",
            "name": "Renjith Manohar"
          },
          "datePublished": "2024-07-12",
          "reviewBody": "This is a great product. I really enjoyed using it!",
          "reviewRating": {
            "@type": "Rating",
            "ratingValue": "5",
            "bestRating": "5"
          }
        },
        {
          "@type": "Review",
          "author": {
            "@type": "Person",
            "name": "Jane Smith"
          },
          "datePublished": "2024-07-14",
          "reviewBody": "Good quality and fast shipping. Highly recommended!",
          "reviewRating": {
            "@type": "Rating",
            "ratingValue": "4",
            "bestRating": "5"
          }
        }
      ]
    }
  `}
      />

      <NavBar />
      <div className="main-container main-container-height">
        <BreadcrumbComponent />
        <section className="product p-0">
          <div className="d-lg-flex align-items-around justify-content-center p-lg-0 p-0 mt-lg-5 text-left ">
            <div className="col-lg-5 mb-3 mb-lg-0 p-0">
              <div className="border preview-image-container">
                <ReactImageMagnify
                  {...{
                    smallImage: {
                      alt: productData[0]?.name,
                      isFluidWidth: true,
                      src: imagePreview,
                      className: "square-image",
                    },
                    largeImage: {
                      src: imagePreview,
                      width: 1200,
                      height: 1800,
                    },
                    enlargedImageContainerClassName: "enlarged-image-container",
                    enlargedImageClassName: "enlarged-image",
                    enlargedImageContainerDimensions: {
                      width: "100%",
                      height: "100%",
                    },
                  }}
                />
              </div>

              <div className="d-flex flex-wrap mt-3">
                {imageArr.map((img) => (
                  <div
                    key={img} // Add a key prop to each item in the list
                    className="card m-2 p-2 a"
                    style={{
                      cursor: "pointer",
                      border: img === imagePreview ? "2px solid black" : "",
                    }}
                  >
                    {" "}
                    <img
                      width={50}
                      src={img}
                      alt={productData[0]?.name}
                      onClick={() => setImagePreview(img)}
                      loading="lazy"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-7">
              <h1 className="mb-3 product-name">{productData[0]?.name}</h1>
              <h2 className="mb-3 product-price d-none">
                {productData[0]?.price && (
                  <span className="">
                    {" "}
                    {currencySymbol} {getConvertedPrice(productData[0]?.price)}
                  </span>
                )}
              </h2>
              {productData[0]?.stock === 0 && (
                <p className="text-danger">
                  The product is not available at the moment
                </p>
              )}

              <div className="row mb-3 container ">
                {productData[0]?.stock !== 0 && (
                  <div className="mr-3">
                    {" "}
                    <AddToCart productData={productData[0]} />
                  </div>
                )}

                <AddToWishlist productData={productData[0]} />
              </div>

              {productData[0]?.colour && (
                <p>
                  {" "}
                  <strong>Colour</strong> : {productData[0]?.colour}
                </p>
              )}

              {productData[0]?.size && (
                <p>
                  {" "}
                  <strong>Size</strong> : {productData[0]?.size}
                </p>
              )}

              <p>
                {" "}
                <strong>Artwork</strong> : {productData[0]?.artwork}
              </p>
              <ProductDescription description={productData[0]?.description} />
            </div>
          </div>
        </section>
      </div>

      <FooterComponent />
    </div>
  );
};

export default ProductPage;

const ProductDescription = ({ description }) => {
  // Split the description at each '\n' character
  const descriptionLines = description?.split("\n");

  return (
    <div>
      {descriptionLines?.map((line, index) =>
        index === 0 ? (
          // Render the first line with the prefix
          <p key={index}>
            <strong>Description</strong> : {line}
          </p>
        ) : (
          // Render subsequent lines normally
          <p key={index}>{line}</p>
        )
      )}
    </div>
  );
};
