import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload, Form } from "antd";
import type { GetProp, UploadFile, UploadProps } from "antd";
import { RcFile } from "antd/es/upload";
import { useApplicationContext } from "../../Context/app-context";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const ImagesUpload: React.FC = () => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const { isProductCreated } = useApplicationContext();
  useEffect(() => {
    if (isProductCreated) {
      setFileList([]); // Clear the fileList state
    }
  }, [isProductCreated]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    // Only keep the originFileObj property
    const filteredFileList: UploadFile<any>[] = newFileList.map((file) => ({
      uid: file.uid,
      name: file.name,
      status: file.status,
      originFileObj: (file as UploadFile<RcFile>).originFileObj,
    }));
    setFileList(filteredFileList);
  };

  const beforeUpload = (file: RcFile) => {
    // Returning false prevents automatic upload
    return false;
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <div className="text-left">
      <Form.Item
        name={`images`}
        label={`Images`}
        rules={[
          { required: true, message: "Please upload at least one image" },
        ]}
      >
        <Upload
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          beforeUpload={beforeUpload} // Prevents automatic upload
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
      </Form.Item>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{ width: "100%" }}
          src={previewImage}
          loading="lazy"
        />
      </Modal>
    </div>
  );
};

export default ImagesUpload;
