import React, { useEffect, useState } from "react";
import logo from "../../logo.png";
import { useNavigate } from "react-router-dom";
import { add_address_api, home_path, login_path } from "../../Config/config";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Tag,
} from "antd";
import AxiosInstance from "../../Config/axios";
import { useApplicationContext } from "../../Context/app-context";
import { PlusOutlined } from "@ant-design/icons";
import CheckoutComponent from "../../Payment/checkout";

const { Option } = Select;
export const CartCheckoutPage = () => {
  const [selectedAddressId, setSelectedAddressId] = useState<number>(0);
  const [price, setPrice] = useState<any>(0);
  const [open, setOpen] = useState(false);
  const { userAddresses, currencySymbol, cartItems, currencyCode } =
    useApplicationContext();
  const navigate = useNavigate();

  const handleAddressSelect = (addressId: number) => {
    setSelectedAddressId(addressId);
  };

  useEffect(() => {
    AxiosInstance.get("get-subtotal").then(async (res) => {
      const subtotal = await res.data;
      setPrice(subtotal);
    });
  }, []);

  useEffect(() => {
    if (userAddresses?.length > 0) {
      setSelectedAddressId(userAddresses[0].id);
    }
  }, [userAddresses]);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  const productNamesString = cartItems.map((item) => item.name).join(", ");
  return (
    <>
      <nav className="nav-bar border-bottom">
        <div className="d-flex justify-content-start align-items-center py-3 container">
          <div>
            <img
              onClick={() => navigate(home_path)}
              id="logo"
              src={logo}
              alt="risheet"
              loading="lazy"
            />
          </div>

          {/* <div>
            <Steps
              size="small"
              items={[
                {
                  title: "Cart",

                  icon: "",
                },
                {
                  title: "Address",
                },
                {
                  title: "Payment",
                },
              ]}
            />
          </div>

          <div className=" nav-menu-left d-flex justify-content-around align-items-center ">
            <p> 100% sure</p>
          </div> */}
        </div>
      </nav>
      <div>
        <div className="container text-left">
          <div className="row">
            <div className="col-lg-6 p-lg-3 p-2">
              {userAddresses?.length > 0 ? (
                <div>
                  <p style={{ fontWeight: "500" }}>Select Delivery Address</p>

                  {userAddresses.map((address) => (
                    <Card key={address.id} style={{ marginBottom: "10px" }}>
                      <div className="mb-3 d-flex ">
                        <Radio
                          onChange={() => handleAddressSelect(address.id)}
                          checked={selectedAddressId === address.id}
                        />
                        <div className="d-flex">
                          <div>
                            {" "}
                            <span
                              style={{ marginLeft: "5px", fontWeight: "bold" }}
                            >
                              {address.name}{" "}
                            </span>
                          </div>

                          {address.type === "both" ? (
                            <div>
                              <Tag className="ml-3">Shipping</Tag>{" "}
                              <Tag className="">Billing</Tag>
                            </div>
                          ) : (
                            <Tag className="ml-3">{address.type}</Tag>
                          )}
                        </div>
                      </div>

                      <div>{`${address.line_1} ${
                        address.line_2 ? `, ${address.line_2}` : ""
                      }`}</div>

                      <div className="mb-2">
                        {` ${address.city}, ${address.state}`},{" "}
                        {`${address.country},  ${address.postcode}`}
                      </div>

                      <div className="mb-2">{`Mobile: ${address.mobile}`}</div>
                      {address.landmark && (
                        <div>{`Landmark: ${address.landmark}`}</div>
                      )}
                    </Card>
                  ))}
                  <Card style={{ borderStyle: "dashed" }} onClick={showModal}>
                    <PlusOutlined /> Add new Address
                  </Card>
                </div>
              ) : (
                <AddressFormComponent setOpen={setOpen} />
              )}
            </div>
            <div className="col-lg-6 px-lg-5 px-3 py-3">
              <p style={{ fontWeight: "500" }}>Price Details (Quantity)</p>
              <p className="d-flex justify-content-between">
                <span>Total MRP </span>{" "}
                <span>
                  {currencySymbol} {price}
                </span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Platform Fee </span>{" "}
                <span className="text-success">Free</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Shipping Fee </span>{" "}
                <span className="text-success">Free</span>
              </p>
              <Divider />
              <p className="d-flex justify-content-between">
                {" "}
                <span> Total Amount</span>{" "}
                <span>
                  {currencySymbol} {price}
                </span>
              </p>
              {userAddresses?.length > 0 && (
                <CheckoutComponent
                  price={price}
                  subject={"Placing Order"}
                  description={productNamesString}
                  text={"Pay now"}
                  currency={currencyCode}
                  addressId={selectedAddressId}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        title="Add Address"
        onCancel={handleCancel}
        maskClosable={false} // Prevent closing when clicking outside the modal
        footer={[]}
      >
        <AddressFormComponent setOpen={setOpen} />
      </Modal>
    </>
  );
};

interface AddressFormComponentProps {
  setOpen: (boolean: boolean) => void;
}

export const AddressFormComponent: React.FC<AddressFormComponentProps> = ({
  setOpen,
}) => {
  const [form] = Form.useForm(); // Get the form instance
  const navigate = useNavigate();
  const [countryNames, setCountryNames] = useState([]);
  const [saveAddressAs, setSaveAddressAs] = useState("");
  const { getAddresses, userAddresses } = useApplicationContext();
  const accessToken = localStorage.getItem("accessToken");
  // Maximum number of allowed addresses
  const MAX_ADDRESSES = 5;

  useEffect(() => {
    fetchCountryNames();
  }, []);

  const fetchCountryNames = () => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        // Extract country names from the response
        const names = data.map((country: any) => country.name.common);
        setCountryNames(names);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  };
  const onFinish = (values: any) => {
    if (userAddresses.length >= MAX_ADDRESSES) {
      // Show modal if the address limit is reached
      setOpen(false);
      form.resetFields();
      showAddressLimitModal();
      return;
    }

    AxiosInstance.post(add_address_api, values, {
      headers: {
        "Content-Type": "application/json",
        token: `Bearer ${accessToken}`,
      },
    })
      .then(async (res) => {
        const response = await res.data;

        if (response.status === 403 || response.status === 403) {
          navigate(login_path);
          return;
        }

        if (response.status === 200) {
          form.resetFields();
          setOpen(false);
          getAddresses();
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSaveAddressAsChange = (e: any) => {
    setSaveAddressAs(e.target.value);
  };

  const showAddressLimitModal = () => {
    const handleOk = () => {
      setOpen(false); // Close the modal
      modal.destroy(); // Destroy the modal
    };

    const modal = Modal.info({
      title: "Address Limit Reached",
      content: (
        <div>
          <p>
            You have reached the maximum limit of {MAX_ADDRESSES} addresses.
          </p>
          <p>Please delete old addresses to add new ones.</p>
        </div>
      ),
      onOk: handleOk,
      okButtonProps: {
        // Props for the OK button
        className: "primary-btn", // Custom class for styling
      },
    });
  };
  return (
    <Card className="rounded-0">
      <Form form={form} name="basic" onFinish={onFinish} layout="vertical">
        <p style={{ fontWeight: "500" }}>Contact Details</p>
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input placeholder="Name" className="primary-input" />
        </Form.Item>
        <Form.Item
          name="mobile"
          rules={[
            {
              required: true,
              message: "Please input your mobile number!",
            },
            {
              pattern: /^[0-9]{10}$/,
              message: "Please input a valid mobile number!",
            },
          ]}
        >
          <Input placeholder="Mobile Number" className="primary-input" />
        </Form.Item>
        <p style={{ fontWeight: "500" }}>Address</p>
        <Form.Item
          name="address1"
          rules={[
            {
              required: true,
              message: "Please input your address line 1!",
            },
          ]}
        >
          <Input placeholder="Address Line 1" className="primary-input" />
        </Form.Item>
        <Form.Item name="address2">
          <Input placeholder="Address Line 2" className="primary-input" />
        </Form.Item>
        <div className="row">
          <Form.Item
            name="postcode"
            className="col-lg-6"
            rules={[
              {
                required: true,
                message: "Please input your postcode!",
              },
            ]}
          >
            <Input placeholder="Postcode" className="primary-input" />
          </Form.Item>
          <Form.Item name="landmark" className="col-lg-6">
            <Input placeholder="Landmark" className="primary-input" />
          </Form.Item>
        </div>

        <div className="row">
          <Form.Item
            className="col-lg-6"
            name="city"
            rules={[
              {
                required: true,
                message: "Please input your city!",
              },
            ]}
          >
            <Input placeholder="City" className="primary-input" />
          </Form.Item>
          <Form.Item
            name="state"
            className="col-lg-6"
            rules={[
              {
                required: true,
                message: "Please input your state!",
              },
            ]}
          >
            <Input placeholder="State" className="primary-input" />
          </Form.Item>
        </div>

        <Form.Item
          name="country"
          rules={[
            {
              required: true,
              message: "Please select your country!",
            },
          ]}
        >
          <Select
            className="primary-input"
            placeholder="Select Country"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children as string | undefined)
                ?.toLowerCase()
                ?.indexOf(input.toLowerCase()) !== -1
            }
          >
            {countryNames.map((name, index) => (
              <Option key={index} value={name}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <p>Save address as</p>
        <Form.Item
          name="type"
          rules={[
            {
              required: true,
              message: "Please choose address type!",
            },
          ]}
        >
          <Radio.Group
            onChange={handleSaveAddressAsChange}
            value={saveAddressAs}
          >
            <Radio value="Shipping">Shipping </Radio>
            <Radio value="Billing">Billing </Radio>
            <Radio value="Both">Both</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button className="primary-btn" htmlType="submit">
            Add Address
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
