import React from "react";
import logo from "../logo.png";
import { Divider, Menu, MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import { useApplicationContext } from "../Context/app-context";

interface AdminSidebarProps {
  setActiveIndex: (index: number) => void;
  activeIndex: number;
}

const items: MenuProps["items"] = [
  {
    label: "Product",
    key: "0",
  },
  {
    label: "Category",
    key: "1",
  },
  {
    label: "Product Type",
    key: "2",
  },
  {
    label: "Artwork",
    key: "3",
  },
  {
    label: "Colour",
    key: "4",
  },
  {
    label: "Size",
    key: "5",
  },
  {
    label: "User",
    key: "6",
  },
  {
    label: "Purchase Order",
    key: "7",
  },
  {
    label: "Invoice",
    key: "8",
  },
];

const AdminSidebar: React.FC<AdminSidebarProps> = ({
  setActiveIndex,
  activeIndex,
}) => {
  const menuOptions = [
    "Product",
    "Category",
    "Product Type",
    "Artwork",
    "Colour",
    "Size",
    "User",
    "Purchase Order",
    "Invoice",
  ];
  const navigate = useNavigate();
  const { isMobile } = useApplicationContext();
  const onClick: MenuProps["onClick"] = (e) => {
    setActiveIndex(parseInt(e.key));
  };
  return isMobile ? (
    <div>
      <header
        className="d-flex justify-content-start px-2 px-lg-0 my-2 align-items-center"
        style={{ gap: "10px" }}
      >
        <img
          onClick={() => navigate("/")}
          id="logo"
          width={100}
          height={50}
          src={logo}
          alt="risheet logo"
          loading="lazy"
        />
      </header>
      <Menu
        onClick={onClick}
        mode="horizontal"
        selectedKeys={[activeIndex.toString()]}
        items={items}
      />
    </div>
  ) : (
    <div className="bg-light text-left py-3 px-2 vh-100">
      <header>
        <img
          onClick={() => navigate("/")}
          id="logo"
          width={100}
          src={logo}
          alt="risheet logo"
          loading="lazy"
          className="ml-2"
        />
      </header>
      <Divider />
      <div className="mt-3">
        <ul className="pl-2" style={{ listStyle: "none" }}>
          {menuOptions.map((option, index) => (
            <li
              className={`mb-3 ${
                index === activeIndex ? "color-primary" : ""
              } menu-option`}
              key={index}
              onClick={() => setActiveIndex(index)}
            >
              {option}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdminSidebar;
