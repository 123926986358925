import { styled } from "styled-components";

export const GalleryStyled = styled.div`
  .image-grid {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(300px, 1fr)
    ); /* Adjust minmax values as needed */
    grid-gap: 10px; /* Adjust gap between images as needed */
  }

  .image-item {
    overflow: hidden; /* Ensure images don't overflow their containers */
  }

  .image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio while covering the container */
  }
  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 10px; /* Adjust the gap between images */
  }

  .gallery-item {
    position: relative;
    margin-bottom: 10px; /* Adjust margin between rows */
  }

  .gallery-item img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px; /* Add border radius for rounded corners */
    object-fit: cover; /* Maintain aspect ratio */
  }
`;
