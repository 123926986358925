import React, { useEffect, useState } from "react";
import NavBar from "../../Layout/nav-bar";
import FooterComponent from "../../Layout/footer";
import {
  cart_checkout_path,
  login_path,
  product_collection_path,
  remove_cart_item_api,
} from "../../Config/config";
import { useNavigate } from "react-router";
import {
  CartItemsType,
  useApplicationContext,
} from "../../Context/app-context";
import { Button, Divider, Modal } from "antd";
import {
  DeleteOutlined,
  LoadingOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AxiosInstance from "../../Config/axios";
import { Helmet } from "react-helmet";
import { MetaTags } from "../../Utils/helperFunction";

const CartPage = () => {
  const navigate = useNavigate();
  const [subTotal, setSubTotal] = useState<string>("");
  const {
    cartItems,
    isCartLoading,
    userData,
    getConvertedPrice,
    currencySymbol,
    getCartItems,
    allProducts,
    isMobile,
  } = useApplicationContext();
  const [updatedCartItems, setUpdatedCartItems] = useState<CartItemsType[]>([]);

  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    // Filter out out-of-stock items and set the updated cart items
    const inStockItems = cartItems.filter((cartItem) => {
      const product = allProducts.find(
        (item) => item.id === cartItem.product_id
      );
      return product && product.stock > 0;
    });

    setUpdatedCartItems(inStockItems);
    calculateSubTotal(inStockItems);

    // Remove out-of-stock items from the cart if user is logged in
    const outOfStockItems = cartItems.filter((cartItem) => {
      const product = allProducts.find(
        (item) => item.id === cartItem.product_id
      );
      return product && product.stock === 0;
    });

    outOfStockItems.forEach((item) => handleRemoveItem(item.product_id, false));
  }, [cartItems, allProducts]);

  const calculateSubTotal = (items: CartItemsType[]) => {
    if (items?.length > 0) {
      const totalPrice = items.reduce(
        (sum, product) => sum + Number(product.price) * product.quantity,
        0
      );
      setSubTotal(totalPrice.toFixed(2));
    }
  };

  const handleQuantityChange = (productId: number, newQuantity: number) => {
    if (newQuantity === 0) {
      handleRemoveItem(productId);
    }

    const filteredCartItem = allProducts.filter(
      (item) => item.id === productId
    );
    const stockQuantity = filteredCartItem[0]?.stock;

    if (newQuantity > stockQuantity) {
      Modal.warning({
        centered: true,
        content:
          "The quantity you've selected exceeds our current stock availability for this item. Please check back later.",
        footer: (
          <div className="d-flex justify-content-center">
            <Button
              className="primary-btn mt-3"
              style={{ width: "100px" }}
              onClick={() => Modal.destroyAll()}
            >
              OK
            </Button>
          </div>
        ),
      });
      return;
    }
    const updatedCartItems = cartItems.map((item) =>
      item.product_id === productId ? { ...item, quantity: newQuantity } : item
    );

    // Update cartItems with new quantity
    setUpdatedCartItems(updatedCartItems);
    calculateSubTotal(updatedCartItems);
  };

  const handleRemoveItem = (productID: number, shouldReload = true) => {
    if (userData) {
      AxiosInstance.post(
        remove_cart_item_api,
        { product_id: productID },
        {
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${accessToken}`,
          },
        }
      )
        .then(async (res) => {
          const response = await res.data;
          if (response.status !== 200) {
            navigate(login_path);
          }
          getCartItems();
        })
        .catch((err) => console.log(err));
    } else {
      // Remove the item from local storage
      const storedCartData: CartItemsType[] = JSON.parse(
        localStorage.getItem("cartData") || "[]"
      );
      const updatedCartData = storedCartData.filter(
        (item) => item.product_id !== productID
      );

      localStorage.setItem("cartData", JSON.stringify(updatedCartData));
      if (shouldReload) {
        window.location.reload();
      }
    }
  };

  const handleCheckout = () => {
    try {
      AxiosInstance.post(
        "/store-subtotal",
        { subTotal: getConvertedPrice(subTotal) },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(async (res) => {
        const response = await res.data;
        console.log("🚀 ~ ).then ~ response:", response);
        navigate(cart_checkout_path);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <MetaTags
        title="Risheet Cart"
        description=""
        robots="noindex, nofollow"
        schema={null}
      />

      <NavBar />
      <div className="text-left main-container main-container-height">
        <p className=" page-header">SHOPPING CART</p>

        {isCartLoading ? (
          <>
            <h4 className="text-center">
              Loading... <LoadingOutlined style={{ fontSize: "20px" }} />
            </h4>
          </>
        ) : (
          <>
            {updatedCartItems.length > 0 ? (
              <div className="row">
                <div className="col-lg-7 mr-lg-5">
                  <p className="mb-3" style={{ fontSize: "25px" }}>
                    Products
                  </p>
                  <Divider />

                  {updatedCartItems.map((product) => (
                    <div
                      className="d-flex align-items-around justify-content-center mb-3 text-left "
                      key={product.product_id}
                    >
                      <div className=" col-lg-5 mb-3 mb-lg-0  p-0  ">
                        <div
                          className="border"
                          onClick={() => {
                            sessionStorage.setItem(
                              "id",
                              product.product_id.toString()
                            );
                            navigate(
                              `${product_collection_path}/${product.transformed_name}`
                            );
                          }}
                        >
                          <img
                            width={"100%"}
                            src={product.imageURLs[0]}
                            alt={product.name}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <p className="mb-3 thumbnail-name">{product.name}</p>
                        <p className="thumbnail-price">
                          <span className="">
                            {currencySymbol} {getConvertedPrice(product.price)}
                          </span>
                        </p>

                        <div className="d-flex d-lg-block">
                          <div
                            className="border px-1 d-flex align-items-center mb-3 "
                            style={{ width: "90px" }}
                          >
                            <MinusOutlined
                              onClick={() =>
                                handleQuantityChange(
                                  product.product_id,
                                  product.quantity - 1
                                )
                              }
                            />
                            <strong className="mx-3">{product.quantity}</strong>

                            <PlusOutlined
                              onClick={() =>
                                handleQuantityChange(
                                  product.product_id,
                                  product.quantity + 1
                                )
                              }
                            />
                          </div>

                          <p
                            style={{ cursor: "pointer" }}
                            className="text-danger col-4 p-0 ml-3 ml-lg-0"
                            onClick={() => handleRemoveItem(product.product_id)}
                          >
                            {isMobile ? <DeleteOutlined /> : "Remove  "}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {isMobile && <Divider />}
                <div className="col-lg-4">
                  <p className="mb-3" style={{ fontSize: "25px" }}>
                    Subtotal
                  </p>
                  <Divider />
                  <h3>
                    {currencySymbol} {getConvertedPrice(subTotal)}
                  </h3>
                  <Button
                    disabled={updatedCartItems.length === 0}
                    className="primary-btn mt-3"
                    onClick={() => {
                      if (userData) {
                        handleCheckout();
                      } else {
                        navigate(login_path);
                      }
                    }}
                  >
                    Check out
                  </Button>
                </div>
              </div>
            ) : (
              <div style={{ height: "30vh" }}>
                <p>
                  There are no items in your cart.{" "}
                  <span
                    onClick={() => navigate(product_collection_path)}
                    className="color-primary"
                    style={{ cursor: "pointer" }}
                  >
                    Continue Shopping{" "}
                  </span>{" "}
                </p>
              </div>
            )}
          </>
        )}
      </div>
      <FooterComponent />
    </div>
  );
};

export default CartPage;
