import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from "../../Layout/nav-bar";
import AxiosInstance from "../../Config/axios";
import { login_path } from "../../Config/config";

const ResetPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const token = new URLSearchParams(location.search).get("token");

  const onFinish = async (values: {
    password: string;
    confirmPassword: string;
  }) => {
    setLoading(true);
    if (values.password !== values.confirmPassword) {
      notification.error({
        message: "Error",
        description: "Passwords do not match!",
      });
      setLoading(false);
      return;
    }
    try {
      // Make API call to reset the password
      await AxiosInstance.post(
        "/api/users/reset-password",
        { password: values.password },
        {
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${token}`,
          },
        }
      );
      notification.success({
        message: "Success",
        description: "Your password has been reset successfully.",
      });
      navigate(login_path);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "There was an error resetting your password.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <NavBar />

      <div className="main-container main-container-height mb-5 text-left">
        <h1 className=" page-header">Reset Password</h1>
        <div style={{ maxWidth: 500 }}>
          <Form name="reset_password" onFinish={onFinish} layout="vertical">
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your new password!" },
                {
                  min: 8,
                  message: "Password must be at least 8 characters long!",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                placeholder="New Password"
                className="text-start primary-input"
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match!")
                    );
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password
                placeholder="Confirm New Password"
                className="text-start primary-input"
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                loading={loading}
                block
                className="primary-btn"
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
