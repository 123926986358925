import { Button } from "antd";
import React from "react";
import { home_path } from "../../Config/config";
import { useNavigate } from "react-router-dom";

const UnAuthorized = () => {
  const navigate = useNavigate();
  return (
    <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
      <div className="container-xxl container-p-y">
        <div className="misc-wrapper">
          <h2 className="mb-2 mx-2">Unauthorized Access :(</h2>
          <p className="mb-4 mx-2">
            Oops! 😖 You are not authorized to access this page.
          </p>
          <Button onClick={() => navigate(home_path)} className="primary-btn">
            Back to home
          </Button>
          <div className="mt-3">
            <img
              src="../assets/img/illustrations/page-misc-error-light.png"
              alt="page-misc-error-light"
              width="500"
              className="img-fluid"
              data-app-dark-img="illustrations/page-misc-error-dark.png"
              data-app-light-img="illustrations/page-misc-error-light.png"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnAuthorized;
