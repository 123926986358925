import React, { useState, useRef, useEffect, Suspense } from "react";
import { useApplicationContext } from "../../Context/app-context";

import Spline from "@splinetool/react-spline";
// const Spline = React.lazy(() => import("@splinetool/react-spline"));

const DoodleAnimation = () => {
  const [reloadKey, setReloadKey] = useState(0); // Key to trigger re-render
  const containerRef = useRef(null);
  const { isMobile } = useApplicationContext();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // When the container is in view, increment the reload key to reload the Spline component
            setReloadKey((prevKey) => prevKey + 1);
          }
        });
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    const reff = containerRef.current;
    //eslint-disable-next-line
    if (reff) {
      observer.observe(reff);
    }

    return () => {
      if (reff) {
        observer.unobserve(reff);
      }
    };
  }, []);

  return (
    <div>
      <center>
        <div
          ref={containerRef}
          style={{
            background:
              "linear-gradient(0deg, rgba(250,213,10,1) 6%, rgba(255,255,255,1) 95%)",
          }}
        >
          <Suspense
            fallback={<div style={{ height: "262px" }}>Loading...</div>}
          >
            <Spline
              // key={reloadKey} // Change key to reload component
              scene={
                isMobile
                  ? "https://prod.spline.design/KHVwFwkw3BLnFe7M/scene.splinecode"
                  : `https://prod.spline.design/29L47c3a-9xb4Jg5/scene.splinecode`
              }
            />
          </Suspense>
        </div>
      </center>
    </div>
  );
};

export default DoodleAnimation;
