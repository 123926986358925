import React from "react";
import {
  AllProductsType,
  useApplicationContext,
} from "../../Context/app-context";
import AxiosInstance from "../../Config/axios";
import {
  add_to_wishlist_api,
  login_path,
  remove_wishlist_item_api,
} from "../../Config/config";
import { useLocation, useNavigate } from "react-router-dom";
import { HeartFilled, HeartOutlined } from "@ant-design/icons";

interface AddToWishlistProps {
  productData: AllProductsType;
}

const AddToWishlist: React.FC<AddToWishlistProps> = ({ productData }) => {
  const { wishlistItems, userData, getWishlistItems } = useApplicationContext();
  const accessToken = localStorage.getItem("accessToken");
  const location = useLocation();
  const navigate = useNavigate();

  const isWishlisted = wishlistItems?.some(
    (item) => item.product_id === productData?.id
  );

  const handleAdd = () => {
    if (!userData) {
      navigate(login_path, {
        state: {
          from: { pathname: location.pathname, search: location.search },
        },
      });
      return;
    }
    const formData = new FormData();
    formData.append("product_id", productData?.id.toString());

    AxiosInstance.post(add_to_wishlist_api, formData, {
      headers: {
        "Content-Type": "application/json",
        token: `Bearer ${accessToken}`,
      },
    })
      .then(async (res) => {
        const response = await res.data;
        if (response.status === 200) {
          getWishlistItems();
        }
      })
      .catch((err) => console.log(err));
  };

  const handleRemove = (productID: number) => {
    AxiosInstance.post(
      remove_wishlist_item_api,
      { product_id: productID },
      {
        headers: {
          "Content-Type": "application/json",
          token: `Bearer ${accessToken}`,
        },
      }
    )
      .then(async (res) => {
        const response = await res.data;
        if (response.status !== 200) {
          navigate(login_path);
        }

        getWishlistItems();
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      {isWishlisted ? (
        <button
          style={{ width: "50px", height: "40px" }}
          className="border rounded bg-white"
          onClick={(e) => {
            e.stopPropagation();
            handleRemove(productData?.id);
          }}
        >
          <HeartFilled style={{ color: "red" }} />
        </button>
      ) : (
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleAdd();
          }}
          style={{ width: "50px", height: "40px" }}
          className="border rounded bg-white"
        >
          <HeartOutlined style={{ color: "black" }} />
        </button>
      )}
    </div>
  );
};

export default AddToWishlist;
