import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { LoadingOutlined, MenuOutlined } from "@ant-design/icons";
import NavBar from "../../Layout/nav-bar";
import FooterComponent from "../../Layout/footer";
import {
  AllProductsType,
  useApplicationContext,
} from "../../Context/app-context";
import { domain, product_collection_path } from "../../Config/config";
import { MetaTags } from "../../Utils/helperFunction";
import FilterSidebar from "../../Layout/filter-side-bar";
import BreadcrumbShop from "./breadcrumb-shop";
import { Drawer, Button } from "antd";
import { useNavigate } from "react-router-dom";

const ShopComponent: React.FC = () => {
  const {
    allProducts,
    isProductsLoading,
    getConvertedPrice,
    currencySymbol,
    filter,
  } = useApplicationContext();

  const navigate = useNavigate();

  const [breadcrumbItems, setBreadcrumbItems] = useState<
    { title: string; href?: string }[]
  >([]);
  const [filteredProducts, setFilteredProducts] = useState<AllProductsType[]>(
    []
  );
  const [categories, setCategories] = useState<
    { name: string; types: string[] }[]
  >([]);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

  useEffect(() => {
    const categories = allProducts.reduce(
      (acc: { name: string; types: string[] }[], product: AllProductsType) => {
        const category = acc.find((c) => c.name === product.category);
        if (category) {
          if (!category.types.includes(product.type)) {
            category.types.push(product.type);
          }
        } else {
          acc.push({ name: product.category, types: [product.type] });
        }
        return acc;
      },
      []
    );
    setCategories(categories);
    setFilteredProducts(allProducts);
  }, [allProducts]);

  useEffect(() => {
    handleFilterChange(filter);
  }, [filter]);

  const handleFilterChange = ({
    category,
    type,
  }: {
    category: string;
    type: string;
  }) => {
    let products = allProducts;
    const items = [];

    if (category) {
      products = products.filter((product) => product.category === category);
      items.push({ title: category });
    }

    if (type) {
      products = products.filter((product) => product.type === type);
      items.push({ title: type });
    }

    setFilteredProducts(products);
    setBreadcrumbItems(items);
    setDrawerVisible(false); // Close the drawer after filter change
  };

  return (
    <>
      <MetaTags
        title="Risheet Shop"
        description=""
        robots="index, follow"
        schema={null}
      />

      <NavBar />

      <div className="main-container-height d-flex">
        <div className="col-lg-2 d-none d-lg-block">
          <FilterSidebar
            categories={categories}
            onFilterChange={handleFilterChange}
          />
        </div>

        <div
          className="main-container col-lg-10"
          style={{ borderLeft: "1px solid #e0e0e0" }}
        >
          <div className="mt-3 mb-4">
            {" "}
            <BreadcrumbShop items={breadcrumbItems} />
          </div>
          <div className="d-lg-none my-2">
            <Button
              icon={<MenuOutlined />}
              onClick={() => setDrawerVisible(true)}
            >
              Filters
            </Button>
          </div>

          <Drawer
            title="Filter Products"
            placement="left"
            closable={true}
            onClose={() => setDrawerVisible(false)}
            visible={drawerVisible}
          >
            <FilterSidebar
              categories={categories}
              onFilterChange={handleFilterChange}
            />
          </Drawer>

          {isProductsLoading ? (
            <div className="mb-5">
              <h3>
                Loading... <LoadingOutlined />
              </h3>
            </div>
          ) : (
            <div>
              {filteredProducts.length > 0 ? (
                <div className="shop-products-container">
                  {filteredProducts.map((product, index) => (
                    <div
                      key={index}
                      className="shop-product-card border"
                      onClick={() => {
                        sessionStorage.setItem("id", product.id.toString());
                        navigate(
                          `${product_collection_path}/${product.transformed_name}`
                        );
                      }}
                    >
                      <div className="square-image-container">
                        <img
                          src={product.imageURLs[0]}
                          alt={product.name}
                          loading="lazy"
                          className="square-image"
                        />
                        {/* {product.stock === 0 && (
                          <div className="sold-out-overlay">
                            <p>Sold Out</p>
                          </div>
                        )} */}
                      </div>

                      <div className="text-left px-2 py-2">
                        <p
                          className="mt-lg-3 thumbnail-name"
                          style={{ height: "40px" }}
                        >
                          {product.name}
                        </p>
                        {/* <p className="thumbnail-price">
                          {currencySymbol} {getConvertedPrice(product.price)}
                        </p> */}
                        <span className="text-danger">Out of stock</span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div style={{ height: "40vh" }} className="text-left">
                  <p>No products found in this collection</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default ShopComponent;
