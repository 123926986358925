import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { home_path, product_collection_path } from "../../Config/config";

interface BreadcrumbProps {
  items: { title: string; href?: string }[];
}

const BreadcrumbShop: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <Breadcrumb>
      <Breadcrumb.Item href={home_path}>
        <HomeOutlined /> Home
      </Breadcrumb.Item>
      <Breadcrumb.Item href={product_collection_path}>Shop</Breadcrumb.Item>
      {items.map((item, index) => (
        <Breadcrumb.Item key={index} href={item.href}>
          {item.title}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbShop;
