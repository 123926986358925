import React, { useEffect, useState } from "react";
import NavBar from "../../Layout/nav-bar";
import FooterComponent from "../../Layout/footer";
import { GalleryStyled } from "./style";
import "yet-another-react-lightbox/styles.css";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { useApplicationContext } from "../../Context/app-context";
import { Helmet } from "react-helmet";
import { domain } from "../../Config/config";
import { MetaTags } from "../../Utils/helperFunction";
const ArtWorksPage = () => {
  return (
    <div>
      <MetaTags
        title="Risheet Gallery"
        description=""
        robots="index, follow"
        schema={null}
      />

      <NavBar />
      <GalleryStyled>
        <div className="art-works">
          <h1 className="page-header text-left mt-5 d-none">Gallery </h1>
          <section className="p-1">
            <Gallery />
          </section>
        </div>
      </GalleryStyled>

      <FooterComponent />
    </div>
  );
};

export default ArtWorksPage;

export const photos = [
  {
    src: "https://res.cloudinary.com/dbgw9jgum/image/upload/v1718794023/Lion_mhrvdc.jpg",
    width: 3516, // Default width
    height: 4371, // Default height
    loading: "lazy",
    alt: "Lion",
  },
  {
    src: "https://res.cloudinary.com/dbgw9jgum/image/upload/v1718794024/Mess-Merising_ijznwm.jpg",
    width: 2777, // Default width
    height: 2776, // Default height
    loading: "lazy",
    alt: "Mess Merishing",
  },
  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1713980817/JAP_SUNRISE_sctybl.jpg",
    width: 2244, // Default width
    height: 1663, // Default height
    loading: "lazy",
    alt: "Jap Sunrise",
  },
  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1714051568/BALLON_POP_1_z0zhmo.jpg",
    width: 3508, // Default width
    height: 4497, // Default height
    loading: "lazy",
    alt: "Balloon Pop",
  },
  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1714049117/WhatsApp_Image_2024-04-25_at_17.38.13_3_axos3s.jpg",
    width: 1600, // Default width
    height: 1200, // Default height
    loading: "lazy",
    alt: "Art designed Wallet",
  },

  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1713980814/BLACK_and_White_DOODLE_1_lfu8tv.jpg",
    width: 3270, // Default width
    height: 2320, // Default height
    loading: "lazy",
    alt: "Black and White Doodle",
  },

  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1713980814/Vibrant_Underwater_1_tp1rco.jpg",
    width: 2131, // Default width
    height: 1512, // Default height
    loading: "lazy",
    alt: "Vibrant Underwater",
  },
  {
    src: "https://res.cloudinary.com/dbgw9jgum/image/upload/v1713428704/IMG-20230625-WA0014_spu7dx.jpg",
    width: 1600, // Default width
    height: 1111, // Default height
    loading: "lazy",
    alt: "Doodle Art",
  },
  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1713980814/Sea_life_-_Design_Elements_d5jh3e.jpg",
    width: 2048, // Default width
    height: 2048, // Default height
    loading: "lazy",
    alt: "Sea Life Art",
  },
  {
    src: "https://res.cloudinary.com/dbgw9jgum/image/upload/v1713428631/20200627_191449_bbvnt2.jpg",
    width: 2359, // Default width
    height: 3400, // Default height
    loading: "lazy",
    alt: "Doodle Art",
  },
  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1713980814/FACES_2_stiavh.jpg",
    width: 2048, // Default width
    height: 2075, // Default height
    loading: "lazy",
    alt: "Doodle Art",
  },
  {
    src: "https://res.cloudinary.com/dbgw9jgum/image/upload/v1714967330/SPACE_1_iz0qfj.jpg",
    width: 2360, // Default width
    height: 1090, // Default height
    loading: "lazy",
    alt: "Space Doodle Art",
  },

  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1714049116/WhatsApp_Image_2024-04-25_at_17.38.13_2_zhphql.jpg",
    width: 1600, // Default width
    height: 1200, // Default height
    loading: "lazy",
    alt: "Doodle Art",
  },

  //--------------------------------------------------------------
  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1713980815/Turtle__nvfswu.jpg",
    width: 1777, // Default width
    height: 1710, // Default height
    loading: "lazy",
    alt: "Turtle Doodle Art",
  },

  {
    src: "https://res.cloudinary.com/dbgw9jgum/image/upload/v1713426520/IMG-20210805-WA0002_f7phkr.jpg",
    width: 1798, // Default width
    height: 1275, // Default height
    loading: "lazy",
    alt: "Doodle Art",
  },

  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1713980818/VIBRANT_CAT_otzgga.jpg",
    width: 1614, // Default width
    height: 1513, // Default height
    loading: "lazy",
    alt: "Cat Doodle Art",
  },
  {
    src: "https://res.cloudinary.com/dbgw9jgum/image/upload/v1715017879/WhatsApp_Image_2024-05-05_at_06.13.57_kbrfun.jpg",
    width: 668, // Default width
    height: 804, // Default height
    loading: "lazy",
    alt: "Doodle Art",
  },

  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1714048815/WhatsApp_Image_2024-04-25_at_18.07.03_lz0slx.jpg",
    width: 1860, // Default width
    height: 1312, // Default height
    loading: "lazy",
    alt: "Doodle Art",
  },

  {
    src: "https://res.cloudinary.com/dbgw9jgum/image/upload/v1718859315/WhatsApp_Image_2024-05-05_at_06.07.19_kvfeul-removebg_o0mvao.png",
    width: 1600, // Default width
    height: 1342, // Default height
    loading: "lazy",
    alt: "Doodle Art",
  },
  {
    src: "https://res.cloudinary.com/dbgw9jgum/image/upload/v1714967330/Retro_Design_feoq24.jpg",
    width: 1837, // Default width
    height: 2646, // Default height
    loading: "lazy",
    alt: "Retro Design Art",
  },

  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1714048815/WhatsApp_Image_2024-04-25_at_18.05.36_xq4ufe.jpg",
    width: 1068, // Default width
    height: 1343, // Default height
    loading: "lazy",
    alt: "Doodle Art",
  },

  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1714049114/WhatsApp_Image_2024-04-25_at_17.36.48_1_xaurva.jpg",
    width: 1416, // Default width
    height: 1317, // Default height
    loading: "lazy",
    alt: "Doodle Art",
  },
  // -----------------------------------

  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1714048814/WhatsApp_Image_2024-04-25_at_18.05.41_mf4efk.jpg",
    width: 2048, // Default width
    height: 1975, // Default height
    loading: "lazy",
    alt: "Doodle Art",
  },
  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1713984917/WhatsApp_Image_2024-04-24_at_19.24.25_zahbju.jpg",
    width: 1600, // Default width
    height: 1200, // Default height
    loading: "lazy",
    alt: "Doodle Art",
  },
  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1714048675/20220117_171512_xyllwn.jpg",
    width: 2227, // Default width
    height: 3078, // Default height
    loading: "lazy",
    alt: "Doodle Art",
  },
  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1714048675/WhatsApp_Image_2024-04-24_at_19.24.44_fsblbl.jpg",
    width: 1600, // Default width
    height: 1200, // Default height
    loading: "lazy",
    alt: "Doodle Art",
  },
  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1714048675/20211019_201605_rpfe84.jpg",
    width: 3589, // Default width
    height: 2649, // Default height
    loading: "lazy",
    alt: "Doodle Art",
  },

  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1714069180/20211018_162802_grinil.jpg",
    width: 2985, // Default width
    height: 3801, // Default height
    alt: "Doodle Art",
    loading: "lazy",
  },
  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1713984917/WhatsApp_Image_2024-04-24_at_19.27.26_qxepso.jpg",
    width: 1114, // Default width
    height: 1600, // Default height
    loading: "lazy",
    alt: "Doodle Art",
  },
  {
    src: "https://res.cloudinary.com/dsw1ubwyh/image/upload/v1714049114/WhatsApp_Image_2024-04-25_at_17.34.47_1_uh2wvh.jpg",
    width: 1600, // Default width
    height: 1200, // Default height
    loading: "lazy",
    alt: "Doodle Art",
  },
];

const Gallery: React.FC = () => {
  const { isMobile } = useApplicationContext();
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  const handleContextMenu = (e: any) => {
    e.preventDefault();
  };

  return (
    <div className="photo-album-container">
      <PhotoAlbum
        photos={photos}
        layout="columns"
        spacing={5}
        columns={isMobile ? 2 : 4}
        onClick={({ index }) => setIndex(index)}
      />

      <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Thumbnails, Zoom]}
      />
    </div>
  );
};
