export const domain = "https://www.risheet.com";

//Route Paths
export const home_path = "/";
export const admin_dashboard_path = "/admin-dashboard";
export const collection_path = "/collection";
export const login_path = "/login";
export const register_path = "/register";
export const cart_path = "/cart";
export const product_collection_path = "/shop";
export const product_collection_category_path = "/collections-category";
export const product_path = "/product";
export const artist_path = "/bio";
export const artworks_path = "/gallery";
export const shipping_path = "/shipping";
export const contact_path = "/contact";
export const user_order_path = "/order";
export const account_path = "/account";
export const wishlist_path = "/wishlist";
export const cart_checkout_path = "/cart-checkout";
export const forgot_password_path = "/forgot-password";
export const reset_password_path = "/reset-password";

//API
export const login_api = "/api/users/login";
export const register_api = "/api/users/register";
export const send_otp_api = "/api/otp/send-otp";
export const verify_otp_api = "/api/otp/verify-otp";
export const check_user_api = "/api/users/check-user";
export const get_all_products_api = "/api/products/all-products";
export const get_product_types_categories_api =
  "/api/products/types-categories";
export const get_product_sizes_api = "/api/products/sizes";
export const get_product_artworks_api = "/api/products/artworks";
export const get_product_colors_api = "/api/products/colors";
export const get_product_categories_api = "/api/products/category";
export const get_product_types_api = "/api/products/types";
export const get_user_data = "api/users/details";
export const add_to_cart_api = "api/cart/add";
export const add_to_wishlist_api = "api/wishlist/add";
export const get_cart_items_api = "api/cart/get";
export const get_wishlist_items_api = "api/wishlist/get";
export const remove_cart_item_api = "api/cart/remove";
export const remove_wishlist_item_api = "api/wishlist/remove";
export const contact_api = "/api/enquiry/send";
export const add_address_api = "/api/address/add";
export const remove_address_api = "/api/address/remove";
export const get_address_api = "/api/address/get";
export const get_all_user_data_api = "/api/admin/user-list";

export const logoColors = [
  "#fd1562",
  "#fe9318",
  "#fad50a",
  "#aeea17",
  "#31d1b1",
  "#4f79f6",
  "#c42cd8",
];
