import React, { useEffect, useState } from "react";
import AdminSidebar from "../../Layout/admin-side-bar";
import ProductsComponent from "./products";
import AxiosInstance from "../../Config/axios";

import {
  get_all_user_data_api,
  get_product_artworks_api,
  get_product_categories_api,
  get_product_colors_api,
  get_product_sizes_api,
  get_product_types_api,
  get_user_data,
} from "../../Config/config";
import { useNavigate } from "react-router-dom";
import UnAuthorized from "../Misc/not-authorized";
import ArtworksComponent from "./artworks";
import ColorsComponent from "./colours";
import SizesComponent from "./sizes";
import CategoriesComponent from "./categories";
import TypesComponent from "./types";
import UsersComponent from "./users";
import { Helmet } from "react-helmet";
import { MetaTags } from "../../Utils/helperFunction";

export interface ProductAttributes {
  id: number;
  name: string;
}

export interface ProductTypesOptionType {
  id: number;
  name: string;
  category_id: number;
}

const AdminDashboard = () => {
  const accessToken = localStorage.getItem("accessToken");
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [adminAuthorized, setAdminAuthorized] = useState<any>(null);
  const [productSizes, setProductSizes] = useState<ProductAttributes[]>([]);
  const [usersList, setUsersList] = useState<any[]>([]);
  const [productArtworks, setProductArtworks] = useState<ProductAttributes[]>(
    []
  );
  const [productColors, setProductColors] = useState<ProductAttributes[]>([]);
  const [mainCategoryOption, setMainCategoryOption] = useState<
    ProductAttributes[]
  >([]);
  const [productTypesOptions, setProductTypesOptions] = useState<
    ProductTypesOptionType[]
  >([]);
  const [trigger, setTrigger] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    AxiosInstance.get(get_product_sizes_api)
      .then(async (res) => {
        const response = await res.data;
        if (response.status === 200) {
          setProductSizes(response.data);
        }
      })
      .catch((err) => console.log(err));
  }, [trigger]);

  useEffect(() => {
    AxiosInstance.get(get_product_artworks_api)
      .then(async (res) => {
        const response = await res.data;
        if (response.status === 200) {
          setProductArtworks(response.data);
        }
      })
      .catch((err) => console.log(err));
  }, [trigger]);

  useEffect(() => {
    AxiosInstance.get(get_product_colors_api)
      .then(async (res) => {
        const response = await res.data;
        if (response.status === 200) {
          setProductColors(response.data);
        }
      })
      .catch((err) => console.log(err));
  }, [trigger]);

  useEffect(() => {
    AxiosInstance.get(get_product_categories_api)
      .then(async (res) => {
        const response = await res.data;
        if (response.status === 200) {
          setMainCategoryOption(response.data);
        }
      })
      .catch((err) => console.log(err));
  }, [trigger]);

  useEffect(() => {
    AxiosInstance.get(get_product_types_api)
      .then(async (res) => {
        const response = await res.data;
        if (response.status === 200) {
          setProductTypesOptions(response.data);
        }
      })
      .catch((err) => console.log(err));
  }, [trigger]);
  useEffect(() => {
    AxiosInstance.get(get_all_user_data_api, {
      headers: {
        "Content-Type": "application/json",
        token: `Bearer ${accessToken}`,
      },
    })
      .then(async (res) => {
        const response = await res.data;
        if (response.status === 200) {
          setUsersList(response.data);
        }
      })
      .catch((err) => console.log(err));
  }, [trigger, accessToken]);

  useEffect(() => {
    AxiosInstance.get(
      get_user_data,

      {
        headers: {
          "Content-Type": "application/json",
          token: `Bearer ${accessToken}`,
        },
      }
    )
      .then((res) => {
        const UserData = res.data?.data;
        if (res.status === 200 && UserData?.type === "admin") {
          setAdminAuthorized(true);
        } else {
          setAdminAuthorized(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setAdminAuthorized(false);
      });
  }, [accessToken, navigate, trigger]);
  const renderComponent = () => {
    switch (activeIndex) {
      case 0:
        return (
          <ProductsComponent
            productTypesOptions={productTypesOptions}
            productArtworks={productArtworks}
            productSizes={productSizes}
            mainCategoryOption={mainCategoryOption}
            productColors={productColors}
            setTrigger={setTrigger}
            trigger={trigger}
          />
        );
      case 1:
        return (
          <CategoriesComponent
            setTrigger={setTrigger}
            trigger={trigger}
            mainCategoryOption={mainCategoryOption}
          />
        );
      case 2:
        return (
          <TypesComponent
            setTrigger={setTrigger}
            trigger={trigger}
            productTypesOptions={productTypesOptions}
            mainCategoryOption={mainCategoryOption}
          />
        );
      case 3:
        return (
          <ArtworksComponent
            setTrigger={setTrigger}
            trigger={trigger}
            productArtworks={productArtworks}
          />
        );
      case 4:
        return (
          <ColorsComponent
            setTrigger={setTrigger}
            trigger={trigger}
            productColors={productColors}
          />
        );
      case 5:
        return (
          <SizesComponent
            setTrigger={setTrigger}
            trigger={trigger}
            productSizes={productSizes}
          />
        );
      case 6:
        return (
          <UsersComponent
            usersList={usersList}
            setTrigger={setTrigger}
            trigger={trigger}
          />
        );
      default:
        return (
          <div
            style={{ display: "grid", placeItems: "center", height: "60vh" }}
          >
            <p>This option available soon</p>
          </div>
        );
    }
  };

  return (
    <>
      <MetaTags
        title="Admin"
        description=""
        robots="noindex, nofollow"
        schema={null}
      />

      {adminAuthorized === null ? (
        <></>
      ) : adminAuthorized ? (
        <div className="d-lg-flex">
          <div className="col-lg-2 p-0">
            <AdminSidebar
              setActiveIndex={setActiveIndex}
              activeIndex={activeIndex}
            />
          </div>

          <div className="mt-3 col-lg-10 col-12 ">
            <div className="p-2 ">{renderComponent()}</div>
          </div>
        </div>
      ) : (
        <UnAuthorized />
      )}
    </>
  );
};

export default AdminDashboard;
