import React from "react";
import Carousel from "./carousel";
import NavBar from "../../Layout/nav-bar";
import FooterComponent from "../../Layout/footer";
import {
  artist_path,
  artworks_path,
  product_collection_path,
} from "../../Config/config";
import { useApplicationContext } from "../../Context/app-context";
import faviconImg from "../../logo512.png";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { MetaTags } from "../../Utils/helperFunction";
import DoodleAnimation from "./animation";

const HomePage: React.FC = () => {
  const { isMobile } = useApplicationContext();
  const navigate = useNavigate();
  return (
    <div>
      <MetaTags
        title="Risheet "
        description=""
        robots="index, follow"
        schema={`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Home Page",
          "url": "https://risheet.com",
          "description": "My name is Risheet Mazumdar and I’m a 14-year-old artist from Sydney, Australia. My artistic style is all about vibrant colours and expressions that pop and hopes to bring a smile to your face!"
          
        }
      `}
      />

      <NavBar />

      <div>
        <h1 className="d-none">Risheet</h1>
        <Carousel />
        {isMobile ? (
          <section className="main-container " style={{ background: "black" }}>
            {/* <Tiles /> */}
            <div>
              <div className="row py-1  mx-lg-0 sub-navigation px-3">
                <div className="col">
                  <Button
                    onClick={() => navigate(product_collection_path)}
                    className="secondary-btn"
                  >
                    SHOP
                  </Button>
                </div>
                <div className="col">
                  <Button
                    onClick={() => navigate(artworks_path)}
                    className="secondary-btn"
                  >
                    GALLERY
                  </Button>
                </div>
                <div className="col ">
                  <Button
                    onClick={() => navigate(artist_path)}
                    className="secondary-btn"
                  >
                    BIO
                  </Button>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        <section
          className=""
          style={{
            background:
              "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(254,147,24,1) 50%, rgba(253,21,98,1) 100%)",
            // color: "white",
            padding: isMobile ? "80px 15px" : "100px",
          }}
        >
          <h3 style={{ fontSize: isMobile ? "24px" : "" }}>
            Explore artworks and merchandise created by 14 year old Australian
            visual artist Risheet.
          </h3>
        </section>
        {/* 
        <section className="p-0">
          <FeaturedComponent />
        </section> */}
        <section className="main-container p-0">
          <img
            src={faviconImg}
            alt="doodle art"
            className="oodlesofdoodles mb-3"
            loading="lazy"
          />
          <h2 style={{ fontSize: "28px" }}>
            "Oodles of doodles,{isMobile ? <br /> : ""} Oodles of happiness"
          </h2>
        </section>
        {/* <section>
          <Categories />
        </section> */}
        <DoodleAnimation />
      </div>
      <FooterComponent />
    </div>
  );
};

export default HomePage;
