import "./App.css";
import AppRoute from "./Routes/app-route";
import { AppContextProvider } from "./Context/app-context";
import { ConfigProvider } from "antd";
import { logoColors } from "./Config/config";

function App() {
  return (
    <div className="App">
      <AppContextProvider>
        <ConfigProvider
          theme={{
            token: {
              // Seed Token
              colorPrimary: logoColors[2],
              fontFamily: "'Jost', sans-serif",
            },
          }}
        >
          <AppRoute />
        </ConfigProvider>
      </AppContextProvider>
    </div>
  );
}

export default App;
