import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Table,
  Upload,
  message,
  notification,
} from "antd";
import CreateProductForm from "./create-product";
import { useNavigate } from "react-router-dom";
import { login_path } from "../../Config/config";
import { useApplicationContext } from "../../Context/app-context";
import { ProductAttributes, ProductTypesOptionType } from ".";
import AxiosInstance from "../../Config/axios";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  MinusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const { Option } = Select;
interface ProductsComponentsProps {
  productTypesOptions: ProductTypesOptionType[];
  productArtworks: ProductAttributes[];
  productSizes: ProductAttributes[];
  mainCategoryOption: ProductAttributes[];
  productColors: ProductAttributes[];
  setTrigger: (trigger: boolean) => void;
  trigger: boolean;
}

const ProductsComponent: React.FC<ProductsComponentsProps> = ({
  productTypesOptions,
  setTrigger,
  trigger,
  productArtworks,
  productSizes,
  mainCategoryOption,
  productColors,
}) => {
  const excludedColumns = [
    "imageURLs",
    "created_date",
    "description",
    "id",
    "dimension",
    "transformed_name",
    "updated_date",
  ];
  const { allProducts, isProductCreated } = useApplicationContext();
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const columns = Object.keys(allProducts[0] || {})
    .map((key) => {
      if (!excludedColumns.includes(key)) {
        return {
          title: key.toUpperCase(),
          dataIndex: key,
        };
      }
      return null;
    })
    .filter((column) => column !== null);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setLoading(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isProductCreated) {
      setOpen(false);
      window.location.reload();
    }
  }, [isProductCreated]);

  return (
    <div className="text-left mt-3">
      <div className="products-list ">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <p className=" page-header m-0">Product</p>
          <div className=" ">
            <Button
              style={{ width: "150px" }}
              onClick={showModal}
              className="primary-btn"
            >
              Add product
            </Button>
          </div>
        </div>

        <ProductTable
          columns={columns}
          data={allProducts}
          productTypesOptions={productTypesOptions}
          productArtworks={productArtworks}
          productSizes={productSizes}
          mainCategoryOption={mainCategoryOption}
          productColors={productColors}
        />
      </div>

      <Modal
        open={open}
        title="Create Product"
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false} // Prevent closing when clicking outside the modal
        footer={[]}
      >
        <CreateProductForm
          productTypesOptions={productTypesOptions}
          productArtworks={productArtworks}
          productSizes={productSizes}
          mainCategoryOption={mainCategoryOption}
          productColors={productColors}
          setTrigger={setTrigger}
          trigger={trigger}
        />
      </Modal>
    </div>
  );
};

export default ProductsComponent;

const ProductTable: React.FC<{
  columns: any[];
  data: any[];
  productTypesOptions: ProductTypesOptionType[];
  productArtworks: ProductAttributes[];
  productSizes: ProductAttributes[];
  mainCategoryOption: ProductAttributes[];
  productColors: ProductAttributes[];
}> = ({
  columns,
  data,
  productTypesOptions,
  productArtworks,
  productSizes,
  mainCategoryOption,
  productColors,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [subCategoryOption, setSubCategoryOption] = useState<
    ProductAttributes[]
  >([]);
  const [entryId, setEntryId] = useState<number>(0);
  const { isMobile } = useApplicationContext();
  const [open, setOpen] = useState(false);
  const accessToken = localStorage.getItem("accessToken");
  const [form] = Form.useForm();
  const updatedColumns = [
    {
      title: "ACTIONS",
      key: "actions",
      width: isMobile ? 70 : 100,
      render: (_: any, record: any) => (
        <div className="d-flex text-left">
          <Button
            onClick={() => showModal(record)}
            className=" btn action-btn p-0 px-lg-2"
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            placement="right" // Set the placement to "top"
            title="Are you sure you want to delete this item?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ style: { width: "50px", background: "black" } }} // Set the width of the OK button
            cancelButtonProps={{ style: { width: "50px" } }} // Set the width of the Cancel button
          >
            <Button className="btn  text-danger action-btn p-0 px-lg-2">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
    ...columns.map((column, index) => ({
      ...column,
      ellipsis: false, // Add ellipsis property to truncate long text
      width: index === 0 ? "" : "150px",

      // Define a custom style for the table cells
      // Adjust the max-width according to your preference
    })),
  ];

  const showModal = (record: any) => {
    setImageUrls(record.imageURLs || []);
    setOpen(true);
    setEntryId(record.id);
    form.setFieldsValue({
      productName: record.name,
      price: parseInt(record.price),
      category: record.category,
      type: record.type,
      size: record.size,
      art: record.artwork,
      color: record.colour,
      description: record.description,
      stockQuantity: record.stock,
      imageUrls: record.imageUrls,
    });
  };

  const handleDelete = (record: any) => {
    AxiosInstance.post(
      "api/admin/delete-product",
      { id: record?.id },
      {
        headers: {
          "Content-Type": "application/json",
          token: `Bearer ${accessToken}`,
        },
      }
    )
      .then(async (res) => {
        const response = await res.data;

        if (response.status === 403 || response.status === 401) {
          alert(response.message);
          navigate(login_path);
        } else if (response.status !== 200) {
          Modal.warning({
            title: "Warning",
            content: response.message,
            footer: (
              <div className="d-flex justify-content-center">
                <Button
                  className="primary-btn mt-3 "
                  style={{ width: "100px" }}
                  onClick={() => Modal.destroyAll()}
                >
                  OK
                </Button>
              </div>
            ),
          });
        }

        if (response.status === 200) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        const errorMessage =
          err.response && Object.keys(err.response.data.message).length !== 0
            ? err.response.data.message
            : "An error occurred while updating artwork.";
        Modal.error({
          centered: true,
          title: "Error",
          content: <p style={{ fontSize: "18px" }}>{errorMessage}</p>,
          footer: (
            <div className="d-flex justify-content-center">
              <Button
                className="primary-btn mt-3 "
                style={{ width: "100px" }}
                onClick={() => Modal.destroyAll()}
              >
                OK
              </Button>
            </div>
          ),
        });
      });
    return {};
  };

  const navigate = useNavigate();

  const handleCancel = () => {
    setOpen(false);
  };

  const findIdByName = (name: string, array: ProductAttributes[]) => {
    const item = array.find((item) => item.name === name);
    return item ? item.id : ""; // Return null if name is not found
  };

  const onFinish = (values: any) => {
    setIsLoading(true);

    // Get the IDs for size, color, category, and type
    const sizeId = findIdByName(values.size, productSizes);
    const colorId = findIdByName(values.color, productColors);
    const artId = findIdByName(values.art, productArtworks);
    const typeId = findIdByName(values.type, productTypesOptions);

    const formData = new FormData();
    formData.append("id", entryId.toString());
    formData.append("productName", values.productName);
    formData.append("price", values.price);
    formData.append("dimension", values.dimension ? values.dimension : "");
    formData.append("type", typeId?.toString());
    formData.append("size", sizeId?.toString());
    formData.append("art", artId?.toString());
    formData.append("color", colorId?.toString());
    formData.append("description", values.description);
    formData.append("stockQuantity", values.stockQuantity);
    formData.append("image_urls", JSON.stringify(imageUrls));

    AxiosInstance.post("api/admin/update-product", formData, {
      headers: {
        "Content-Type": "application/json",
        token: `Bearer ${accessToken}`,
      },
    })
      .then(async (res) => {
        setIsLoading(false);
        const response = await res.data;

        if (response.status === 403 || response.status === 401) {
          alert(response.message);
          navigate(login_path);
        }

        if (response.status === 200) {
          form.resetFields(); // Reset the form fields
          setOpen(false);
          message.success("Product updated successfully!");
          window.location.reload();
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCategoryChange = (value: string) => {
    const artId = findIdByName(value, mainCategoryOption);

    const filtered = productTypesOptions.filter(
      (item) => item.category_id === artId
    );

    const uniqueTypesWithId = filtered.filter(
      (type, index, self) => index === self.findIndex((c) => c.id === type.id)
    );

    setSubCategoryOption(uniqueTypesWithId);

    // Clear the type field
    form.setFieldsValue({
      type: undefined,
    });
  };
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const handleUpload = async (file: any) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("image", file);
      const response = await AxiosInstance.post(
        "/api/admin/upload-image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `Bearer ${accessToken}`,
          },
        }
      );

      setImageUrls([...imageUrls, response.data.url]);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to upload image.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const updatedImageUrls = Array.from(imageUrls);
    const [removed] = updatedImageUrls.splice(result.source.index, 1);
    updatedImageUrls.splice(result.destination.index, 0, removed);
    setImageUrls(updatedImageUrls);
  };

  const handleRemove = (index: number) => {
    const updatedImageUrls = imageUrls.filter((_, i) => i !== index);
    setImageUrls(updatedImageUrls);
  };
  return (
    <div>
      <Table
        columns={updatedColumns}
        dataSource={data}
        pagination={false}
        scroll={{ y: "70vh", x: isMobile ? "2000px" : "1480px" }}

        // onRow={(record) => ({
        //   onClick: () => handleRowClick(record),
        // })}
      />
      <Modal
        open={open}
        title="Update Product"
        onCancel={handleCancel}
        maskClosable={false} // Prevent closing when clicking outside the modal
        footer={[]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          // onValuesChange={handleFormValuesChange}
        >
          <div className="row">
            <Form.Item
              name="productName"
              label="Product Name"
              rules={[{ required: true, message: "Please enter product name" }]}
              className="col-lg-6"
            >
              <Input
                className="primary-input"
                style={{ borderRadius: 0 }}
                placeholder="Enter"
              />
            </Form.Item>

            <Form.Item
              name="price"
              label="Price"
              rules={[
                {
                  required: true,
                  type: "number",
                  min: 0,
                  message: "Please enter a valid price",
                },
              ]}
              className="col-lg-6"
            >
              <InputNumber
                style={{ width: "100%", borderRadius: "0" }}
                formatter={(value: string | undefined) =>
                  `AU$ ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value: string | undefined) =>
                  value ? value.replace(/AU\$\s?|(,*)/g, "") : ""
                }
                className="primary-input"
              />
            </Form.Item>
          </div>
          <div className="row">
            <div className="col-lg-6 " style={{ marginBottom: "24px" }}>
              <Form.Item
                name="category"
                label="Category"
                rules={[{ required: true, message: "Please select category" }]}
                className="m-0"
              >
                <Select
                  className="primary-input"
                  style={{ borderRadius: 0 }}
                  placeholder="Select"
                  onChange={handleCategoryChange}
                >
                  {" "}
                  {mainCategoryOption.map((c) => (
                    <Option key={c.id} value={c.name}>
                      {c.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-lg-6" style={{ marginBottom: "24px" }}>
              <Form.Item
                name="type"
                label="Type"
                rules={[{ required: true, message: "Please select type" }]}
                className="m-0"
              >
                <Select
                  className="primary-input"
                  style={{ borderRadius: 0 }}
                  placeholder="Select"
                >
                  {" "}
                  {subCategoryOption.map((c) => (
                    <Option key={c.id} value={c.name}>
                      {c.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <Form.Item name="dimension" label="Dimension" className="col-lg-6">
              <Input
                className="primary-input"
                style={{ borderRadius: 0 }}
                placeholder="Enter"
              />
            </Form.Item>
            <Form.Item
              name="size"
              label="Size"
              rules={[{ required: true, message: "Please select size" }]}
              className="col-lg-6"
            >
              <Select
                className="primary-input"
                style={{ borderRadius: 0 }}
                placeholder="Select"
              >
                {" "}
                {productSizes.map((c) => (
                  <Option key={c.id} value={c.name}>
                    {c.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="row">
            <div className="col-lg-6" style={{ marginBottom: "24px" }}>
              <Form.Item
                name="art"
                label="Art work"
                rules={[{ required: true, message: "Please select art" }]}
                className="m-0"
              >
                <Select
                  className="primary-input"
                  style={{ borderRadius: 0 }}
                  placeholder="Select"
                >
                  {" "}
                  {productArtworks.map((c) => (
                    <Option key={c.id} value={c.name}>
                      {c.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-lg-6" style={{ marginBottom: "24px" }}>
              <Form.Item
                name="color"
                label="Colour"
                rules={[{ required: true, message: "Please select colour" }]}
                className="m-0"
              >
                <Select
                  className="primary-input"
                  style={{ borderRadius: 0 }}
                  placeholder="Select"
                >
                  {" "}
                  {productColors.map((c) => (
                    <Option key={c.id} value={c.name}>
                      {c.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Please enter description" }]}
          >
            <Input.TextArea
              className="primary-textarea"
              rows={8}
              style={{ borderRadius: 0 }}
              placeholder="Enter"
            />
          </Form.Item>

          <Form.Item
            name="stockQuantity"
            label="Stock Quantity"
            rules={[
              {
                required: true,
                type: "number",
                min: 0,
                message: "Please enter a valid stock quantity",
              },
            ]}
          >
            <InputNumber
              className="primary-input"
              style={{ width: "100%", borderRadius: "0" }}
            />
          </Form.Item>

          <p>Drag the image to reorder </p>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="images" direction="horizontal">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="image-list"
                >
                  {imageUrls.map((url, index) => (
                    <Draggable key={url} draggableId={url} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="image-item"
                        >
                          <div className="image-container-drag">
                            <img
                              src={url}
                              alt={`Product ${index}`}
                              className="product-image"
                            />
                            <Popconfirm
                              title="Are you sure to remove this image?"
                              onConfirm={() => handleRemove(index)}
                              okText="Yes"
                              cancelText="No"
                              okButtonProps={{
                                style: { width: "50px", background: "black" },
                              }} // Set the width of the OK button
                              cancelButtonProps={{ style: { width: "50px" } }} // Set the width of the Cancel button
                            >
                              <Button
                                shape="circle"
                                icon={<MinusOutlined />}
                                className="remove-button"
                              />
                            </Popconfirm>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Form.Item>
            <Upload
              customRequest={({ file }) => handleUpload(file)}
              listType="picture"
              showUploadList={false}
            >
              <Button
                icon={<UploadOutlined />}
                loading={loading}
                className="w-100 my-3"
              >
                Add new image
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              className="primary-btn"
              disabled={loading}
            >
              {isLoading ? (
                <span>
                  Update <LoadingOutlined />
                </span>
              ) : (
                "Update"
              )}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
