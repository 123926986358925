import NavBar from "../../Layout/nav-bar";
import FooterComponent from "../../Layout/footer";
import { Helmet } from "react-helmet";
import RisheetImg from "../../Images/Risheet.png";
import { domain } from "../../Config/config";
import { MetaTags } from "../../Utils/helperFunction";


const ArtistPage = () => {
  return (
    <>
      <MetaTags
        title="Bio | Risheet"
        description=""
        robots="index, follow"
        schema={`
        {
          "@context": "http://schema.org",
          "@type": "Person",
          "name": "Risheet Mazumdar",
          "description": "Hi! My name is Risheet Mazumdar and I’m a 14-year-old doodle/pop artist from Sydney, Australia. My art style
          has utilised various mediums, like ink and markers, paint pens, and
          in recent times I’ve been more focused on digital art.",
          "image": "https://res.cloudinary.com/dbgw9jgum/image/upload/v1714539425/WhatsApp_Image_2024-04-24_at_19.41.28_oyeiz5_n5z4b9.jpg",
          "sameAs": [
            "https://www.youtube.com/channel/UC5Yg2UE5coog2AunnASmJRg",
            "https://www.instagram.com/risheetmazumdar/",
            "https://www.linkedin.com/in/risheet-mazumdar/"
          ]
        }
      `}
      />

      <NavBar />
      <div className="text-left main-container">
        <h1 className="page-header m-0">BIO </h1>
        <div className="artist-page  main-container-height d-lg-flex ">
          <div className="col-lg-3 p-0">
            <img src={RisheetImg} alt="Risheet" className="border mt-5" />
          </div>
          <section className="artist-info text-left p-0 col-lg-9">
            <p>
              My name is Risheet Mazumdar and I’m a 14-year-old artist from
              Sydney, Australia. My artistic style is all about vibrant colours
              and expressions that pop and hopes to bring a smile to your face!
            </p>

            <p>
              I'm extremely passionate about what I do, and often take
              inspiration from everyday things that I love and excite me. From
              as long as I can remember, I’ve always been creative. When I’m not
              creating art, you can catch me singing on stage, clicking away
              photos on my camera, cooking up a feast at home, playing other
              musical instruments, or productising my art!
            </p>

            <p>
              COVID lockdowns were a significant turning point for me. I had
              lots of time on my hand to develop my passion and that made me
              realise that I wanted to set a personal goal for myself to take my
              art further than what you might just see on a canvas.
            </p>

            <p>
              Since I began to develop my own style of art, I’ve been featured
              in an issue of the local Northern Beaches magazine, ‘The Tawny
              Frogmouth’ at the age of 11, winning the ‘Mosman Youth Art Prize’,
              at 12, and being a finalist of the ‘Northern Beaches Environmental
              Art and Design Prize’ the following year. Soon after, I was
              selected and given the opportunity to collaborate with 5 other
              artists from across the Northern beaches, where we spent a few
              days creating a mural in the Manly library.
            </p>

            <p>
              In parallel, I’ve had the chance to have some short-term
              exhibitions in local galleries. I began reaching out to
              manufacturers to productise my art. Soon I began to get orders and
              opportunities for custom works as well. I have huge dreams for
              this and hope to take my passion far!
            </p>

            <p>
              Please follow my journey! Feel free to connect with me on
              Instagram{" "}
              <a
                href="https://www.instagram.com/risheetmazumdar/"
                target="blank"
              >
                @risheetmazumdar
              </a>{" "}
              or any other social media handles below.
            </p>
          </section>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

export default ArtistPage;
