import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import React, { useState, useEffect, lazy, Suspense } from "react";

import img4 from "../../Images/Vibrant_Underwater.webp";
import img5 from "../../Images/SPACE.webp";
import img2 from "../../Images/Untitled_Artwork.webp";
import img1 from "../../Images/Black_And_White_Doodle.webp";
import img3 from "../../Images/Expressions-_Patterns.webp";
import { useApplicationContext } from "../../Context/app-context";

const LazyBackgroundImage = lazy(
  () => import("../../Utils/lazyLoadBackground")
);

const Carousel: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const totalSlides = 5; // Total number of slides
  const transitionDuration = 500; // Transition duration in milliseconds
  const intervalDuration = 5000; // Interval duration in milliseconds
  // const navigateLink = useNavigate();
  const { isMobile } = useApplicationContext();

  const images = [
    { src: img1, alt: "Black and White Doodle" },
    { src: img2, alt: "Untitled Artwork" },
    { src: img3, alt: "Expressions Patterns" },
    { src: img4, alt: "Vibrant Underwater" },
    { src: img5, alt: "Space" },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      navigate("next");
    }, intervalDuration);

    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, [currentSlide]);

  const navigate = (direction: "prev" | "next") => {
    let newSlide: number;
    if (direction === "next") {
      newSlide = currentSlide === totalSlides - 1 ? 0 : currentSlide + 1;
    } else {
      newSlide = currentSlide === 0 ? totalSlides - 1 : currentSlide - 1;
    }
    setCurrentSlide(newSlide);
  };

  return (
    <div className="carousel-container">
      <div
        style={{
          display: "flex",
          transform: `translateX(-${currentSlide * 100}%)`,
          transition: `transform ${transitionDuration / 1000}s ease-in-out`,
        }}
      >
        {images.map((img, index) => (
          <Suspense
            key={index}
            fallback={
              <div style={{ minHeight: isMobile ? "25vh" : "70vh" }}>
                Loading...
              </div>
            }
          >
            <LazyBackgroundImage src={img.src} alt={img.alt} />
          </Suspense>
        ))}
      </div>
      <div
        onClick={() => navigate("prev")}
        className="carousel-btn-left"
        style={{
          opacity: currentSlide === 0 ? 0.9 : 1,
        }}
      >
        <LeftOutlined />
      </div>
      <div
        onClick={() => navigate("next")}
        className="carousel-btn-right"
        style={{
          opacity: currentSlide === totalSlides - 1 ? 0.9 : 1,
        }}
      >
        <RightOutlined />
      </div>
    </div>
  );
};

export default Carousel;
