import { useState } from "react";
import { useApplicationContext } from "../../Context/app-context";
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Skeleton,
  Table,
} from "antd";
import { ProductAttributes, ProductTypesOptionType } from ".";
import AxiosInstance from "../../Config/axios";
import { login_path } from "../../Config/config";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const { Option } = Select;

interface TypesComponentsProps {
  productTypesOptions: ProductTypesOptionType[];
  mainCategoryOption: ProductAttributes[];
  setTrigger: (trigger: boolean) => void;
  trigger: boolean;
}

const TypesComponent: React.FC<TypesComponentsProps> = ({
  setTrigger,
  trigger,
  productTypesOptions,
  mainCategoryOption,
}) => {
  const accessToken = localStorage.getItem("accessToken");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const excludedColumns = ["id", "category_id"];
  const columns = Object.keys(productTypesOptions[0] || {})
    .map((key) => {
      if (!excludedColumns.includes(key)) {
        return {
          title: key.toUpperCase(),
          dataIndex: key,
        };
      }
      return null;
    })
    .filter((column) => column !== null);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    form.setFieldsValue({
      type: undefined,
    });
    setOpen(false);
  };

  const handleTypeAdd = () => {
    AxiosInstance.post(
      "api/admin/create-type",
      { type: form.getFieldValue("type"), categoryId: selectedCategory },
      {
        headers: {
          "Content-Type": "application/json",
          token: `Bearer ${accessToken}`,
        },
      }
    )
      .then(async (res) => {
        const response = await res.data;

        if (response.status === 403 || response.status === 401) {
          alert(response.message);
          navigate(login_path);
        }

        if (response.status === 200) {
          setTrigger(!trigger);
          form.resetFields();
          setOpen(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCategoryChange = (value: string) => {
    setSelectedCategory(value);
  };
  return (
    <div className="text-left mt-3">
      <div className="products-list ">
        <div className="d-flex justify-content-between align-items-start flex-wrap mb-3">
          <p className="mb-1 page-header">Product Type</p>

          <div className="">
            <Button
              style={{ width: "150px" }}
              onClick={showModal}
              className="primary-btn"
            >
              Add type
            </Button>
          </div>
        </div>
        <TypesTable
          columns={columns}
          data={productTypesOptions}
          mainCategoryOption={mainCategoryOption}
          setTrigger={setTrigger}
          trigger={trigger}
        />
      </div>
      <Modal
        open={open}
        title="Create Type"
        onCancel={handleCancel}
        maskClosable={false} // Prevent closing when clicking outside the modal
        footer={[]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleTypeAdd}
          // onValuesChange={handleFormValuesChange}
        >
          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true, message: "Please select category" }]}
          >
            <Select
              className="primary-input"
              onChange={handleCategoryChange}
              style={{ borderRadius: 0 }}
              placeholder="Select"
            >
              {" "}
              {mainCategoryOption.map((c) => (
                <Option key={c.id} value={c.id}>
                  {c.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="type" className="mb-3">
            <Input
              style={{ borderRadius: 0 }}
              placeholder="Enter"
              className="primary-input"
              disabled={!selectedCategory}
            />
          </Form.Item>

          <button
            type="submit"
            className=" btn border py-1 px-2 btn-dark"
            style={{ fontSize: "12px" }}
          >
            Save
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();

              handleCancel();
            }}
            className="btn border py-1 ml-2 px-2 btn-dark"
            style={{ fontSize: "12px" }}
          >
            Cancel
          </button>
        </Form>
      </Modal>
    </div>
  );
};

export default TypesComponent;

const TypesTable: React.FC<{
  columns: any[];
  data: any[];
  setTrigger: (trigger: boolean) => void;
  trigger: boolean;
  mainCategoryOption: ProductAttributes[];
}> = ({ columns, data, setTrigger, trigger, mainCategoryOption }) => {
  console.log("🚀 ~ mainCategoryOption:", mainCategoryOption);
  const { isMobile } = useApplicationContext();
  const [open, setOpen] = useState(false);
  const [entryId, setEntryId] = useState<number>(0);
  const [form] = Form.useForm();
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const updatedColumns = [
    {
      title: "ACTIONS",
      key: "actions",
      width: isMobile ? "" : 250,
      render: (_: any, record: any) => (
        <div className="d-flex">
          <Button onClick={() => showModal(record)} className=" btn action-btn">
            <EditOutlined />
          </Button>
          <Popconfirm
            placement="right" // Set the placement to "top"
            title="Are you sure you want to delete this item?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ style: { width: "50px", background: "black" } }} // Set the width of the OK button
            cancelButtonProps={{ style: { width: "50px" } }} // Set the width of the Cancel button
          >
            <Button className="btn  text-danger action-btn">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
    ...columns.map((column, index) => ({
      ...column,
      ellipsis: false, // Add ellipsis property to truncate long text
      // Define a custom style for the table cells
      // Adjust the max-width according to your preference
    })),
  ];

  const findIdByName = (name: string, array: ProductAttributes[]) => {
    const item = array.find((item) => item.name === name);
    return item ? item.id : ""; // Return null if name is not found
  };

  const handleUpdate = () => {
    AxiosInstance.post(
      "api/admin/update-type",
      {
        id: entryId,
        name: form.getFieldValue("name"),
        categoryId: findIdByName(
          form.getFieldValue("category"),
          mainCategoryOption
        ),
      },
      {
        headers: {
          "Content-Type": "application/json",
          token: `Bearer ${accessToken}`,
        },
      }
    )
      .then(async (res) => {
        const response = await res.data;

        if (response.status === 403 || response.status === 401) {
          alert(response.message);
          navigate(login_path);
        } else if (response.status !== 200) {
          Modal.info({
            title: "Info",
            content: response.message,
          });
        }

        if (response.status === 200) {
          form.resetFields();
          setOpen(false);
          setTrigger(!trigger);
        }
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err.response
          ? err.response.data.message
          : "An error occurred while updating type.";
        Modal.error({
          title: "Error",
          content: errorMessage,
        });
      });
  };
  const handleDelete = (record: any) => {
    AxiosInstance.post(
      "api/admin/delete-type",
      { id: record?.id },
      {
        headers: {
          "Content-Type": "application/json",
          token: `Bearer ${accessToken}`,
        },
      }
    )
      .then(async (res) => {
        const response = await res.data;

        if (response.status === 403 || response.status === 401) {
          alert(response.message);
          navigate(login_path);
        } else if (response.status !== 200) {
          Modal.warning({
            title: "Warning",
            content: response.message,
            footer: (
              <div className="d-flex justify-content-center">
                <Button
                  className="primary-btn mt-3 "
                  style={{ width: "100px" }}
                  onClick={() => Modal.destroyAll()}
                >
                  OK
                </Button>
              </div>
            ),
          });
        }

        if (response.status === 200) {
          setTrigger(!trigger);
        }
      })
      .catch((err) => {
        console.log(err);
        const errorMessage =
          err.response && Object.keys(err.response.data.message).length !== 0
            ? err.response.data.message
            : "An error occurred while updating type.";
        Modal.error({
          centered: true,
          title: "Error",
          content: <p style={{ fontSize: "18px" }}>{errorMessage}</p>,
          footer: (
            <div className="d-flex justify-content-center">
              <Button
                className="primary-btn mt-3 "
                style={{ width: "100px" }}
                onClick={() => Modal.destroyAll()}
              >
                OK
              </Button>
            </div>
          ),
        });
      });
    return {};
  };

  const showModal = (record: any) => {
    setOpen(true);
    setEntryId(record?.id);
    form.setFieldsValue({
      name: record?.name,
      category: record?.category,
    });
  };

  const handleCancel = () => {
    form.setFieldsValue({
      type: undefined,
    });
    setOpen(false);
  };

  return (
    <Skeleton loading={data.length === 0} active>
      <Table
        columns={updatedColumns}
        dataSource={data}
        pagination={false}
        scroll={{ y: "70vh", x: "" }}
      />
      <Modal
        open={open}
        title="Update Type"
        onCancel={handleCancel}
        maskClosable={false} // Prevent closing when clicking outside the modal
        footer={[]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleUpdate}
          // onValuesChange={handleFormValuesChange}
        >
          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true, message: "Please select category" }]}
          >
            <Select
              className="primary-input"
              style={{ borderRadius: 0 }}
              placeholder="Select"
            >
              {" "}
              {mainCategoryOption.map((c) => (
                <Option key={c.id} value={c.name}>
                  {c.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="name" className="mb-3">
            <Input
              style={{ borderRadius: 0 }}
              placeholder="Enter"
              className="primary-input"
            />
          </Form.Item>

          <button
            type="submit"
            className=" btn border py-1 px-2 btn-dark"
            style={{ fontSize: "12px" }}
          >
            Save
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();

              handleCancel();
            }}
            className="btn border py-1 ml-2 px-2 btn-dark"
            style={{ fontSize: "12px" }}
          >
            Cancel
          </button>
        </Form>
      </Modal>
    </Skeleton>
  );
};
