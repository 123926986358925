import { useLocation } from "react-router-dom";
import { domain } from "../Config/config";
import { Helmet } from "react-helmet";

export const transformProductName = (name: string) => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

export const decodeProductName = (name: string) => {
  return name.replace(/-/g, " ");
};

export const trimDescription = (description: string, maxLength = 190) => {
  if (description?.length > maxLength) {
    return description.substring(0, maxLength) + "...";
  }
  return description;
};

type MetaTagsProps = {
  title: string;
  description: string;
  robots: string;
  schema: any;
};

export const MetaTags: React.FC<MetaTagsProps> = ({
  title,
  description,
  robots,
  schema,
}) => {
  const location = useLocation();
  const path = location.pathname;

  const defaults = {
    title: "Risheet",
    description:
      "My name is Risheet Mazumdar and I’m a 14-year-old artist from Sydney, Australia. My artistic style is all about vibrant colours and expressions that pop and hopes to bring a smile to your face!",
    canonicalUrl: `${domain}${path}`,
    robots: "noindex, nofollow",
  };

  const finalTitle = title || defaults.title;
  const finalDescription = trimDescription(description || defaults.description);
  const finalCanonicalUrl = defaults.canonicalUrl;
  const finalRobots = robots || defaults.robots;

  return (
    <Helmet>
      <title>{finalTitle}</title>
      <meta property="og:title" content={finalTitle} />
      <link rel="canonical" href={finalCanonicalUrl} />
      <meta name="description" content={finalDescription} />
      <meta property="og:description" content={finalDescription} />
      <meta name="robots" content={finalRobots} />
      {schema && <script type="application/ld+json">{schema}</script>}
    </Helmet>
  );
};
