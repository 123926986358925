import React, { useState } from "react";
import logo from "../logo.png";
import {
  account_path,
  admin_dashboard_path,
  artist_path,
  artworks_path,
  cart_path,
  contact_path,
  home_path,
  login_path,
  product_collection_category_path,
  product_collection_path,
  user_order_path,
  wishlist_path,
} from "../Config/config";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  HeartOutlined,
  InboxOutlined,
  LoadingOutlined,
  LogoutOutlined,
  MenuOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useApplicationContext } from "../Context/app-context";
import { Drawer } from "antd";

interface Product {
  type: string;
  category: string;
}
interface CategoriesMap {
  [category: string]: Product[];
}

const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const { isMobile, userData, setUserData } = useApplicationContext();
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;
  const [showUserDropdown, setShowUserDropdown] = useState<boolean>(false);

  const handleToggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };
  const profileMenuItems = [
    {
      key: "1",
      title: (
        <p
          className={`m-1 ${isActive(user_order_path) ? "selected-tab" : ""}`}
          style={{ cursor: "pointer" }}
          onClick={() => navigate(user_order_path)}
        >
          My order
        </p>
      ),
    },
    {
      key: "2",
      title: (
        <p
          className={`m-1 ${isActive(account_path) ? "selected-tab" : ""}`}
          style={{ cursor: "pointer" }}
          onClick={() => navigate(account_path)}
        >
          My details
        </p>
      ),
    },
    {
      key: "3",
      title: (
        <p
          className="m-1"
          onClick={() => {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("cartData");
            setUserData("");
            navigate(home_path);
          }}
          style={{ cursor: "pointer" }}
        >
          Log out
        </p>
      ),
    },
  ];

  const handleMouseEnter = () => {
    setShowUserDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowUserDropdown(false);
  };
  return (
    <nav className="nav-bar">
      <div className="d-flex justify-content-between align-items-center py-3 container">
        <div>
          <img
            onClick={() => navigate(home_path)}
            id="logo"
            src={logo}
            alt="risheet logo"
            loading="lazy"
            width={100}
            height={50}
          />
        </div>

        {isMobile ? (
          <Drawer
            placement="right"
            closable={true}
            onClose={() => setShowMobileMenu(false)}
            visible={showMobileMenu}
          >
            {" "}
            <MenuItems navigate={navigate} />
          </Drawer>
        ) : (
          <MenuItems navigate={navigate} />
        )}

        <div className=" nav-menu-left d-flex justify-content-around align-items-center ">
          {userData && userData.type === "admin" && (
            <p
              className="m-0 mr-3 menu-option"
              onClick={() => navigate(admin_dashboard_path)}
            >
              Admin
            </p>
          )}
          <div>
            {userData ? (
              <div
                style={{ position: "relative" }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <p
                  className={`m-0 menu-option mr-3 ${
                    isActive(login_path) ? "selected-tab" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  <UserOutlined />
                </p>

                <div className="" style={{ color: "black" }}>
                  {showUserDropdown && (
                    <div
                      className={`dropdown-container shadow ${
                        isMobile ? "mobile-dropdown" : ""
                      }`}
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: -30,
                        zIndex: 999,
                        width: "150px",
                        background: "white",
                      }}
                    >
                      <div className="text-left p-2">
                        <div className="" style={{ color: "black" }}>
                          {profileMenuItems.map((item) => (
                            <div key={item.key} className="col m-2 mb-3">
                              {item.title}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <p
                className={`m-0 menu-option mr-3 ${
                  isActive(login_path) ? "selected-tab" : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => navigate(login_path)}
              >
                <UserOutlined />
              </p>
            )}
          </div>

          {/* <Badge count={cartItems?.length} color="black"> */}
          <p
            onClick={() => navigate(cart_path)}
            className={`m-0 menu-option mr-3 ${
              isActive(cart_path) ? "selected-tab" : ""
            }`}
          >
            <ShoppingCartOutlined className="ml-2" />{" "}
          </p>
          {/* </Badge> */}
          <p
            className={`m-0 menu-option mr-3 ${
              isActive(wishlist_path) ? "selected-tab" : ""
            }`}
            onClick={() => navigate(wishlist_path)}
            style={{ cursor: "pointer" }}
          >
            <HeartOutlined />
          </p>

          {/* <div className="d-flex">
            <div className="col-2">
              <img src={deliveryIcon} alt="risheet" width={30} />
            </div>

            <p style={{ fontSize: "14px" }} className="col-10">
              Free Shipping in Australia Orders Over $100
            </p>
          </div> */}

          {isMobile && <MenuOutlined onClick={handleToggleMobileMenu} />}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;

const MenuItems: React.FC<{ navigate: any }> = ({ navigate }) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const { isMobile, setFilter, productTypes, isProductsLoading, allProducts } =
    useApplicationContext();
  const location = useLocation();

  const isActive = (path: string) => location.pathname === path;

  const handleMenuClick = (category: string, type: string = "") => {
    setFilter({ category, type });
    if (location.pathname !== product_collection_path) {
      navigate(product_collection_path);
    }
  };

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  const categoriesMap: CategoriesMap = {};

  // Group products by category
  productTypes.forEach((product) => {
    if (!categoriesMap[product.category]) {
      categoriesMap[product.category] = [];
    }
    categoriesMap[product.category].push(product);
  });

  // Filter out categories and types with existing products
  const filteredCategoriesMap: CategoriesMap = {};
  Object.entries(categoriesMap).forEach(([category, types]) => {
    const filteredTypes = types.filter((type) =>
      allProducts.some(
        (product) => product.category === category && product.type === type.type
      )
    );
    if (filteredTypes.length > 0) {
      filteredCategoriesMap[category] = filteredTypes;
    }
  });

  return (
    <div className="nav-menu">
      <ul>
        <li
          style={{ position: "relative" }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className={isActive(product_collection_path) ? "selected-tab" : ""}
        >
          <a href={product_collection_path}>
            <span
              className={`menu-option ${
                isActive(product_collection_path) ? "selected-tab" : ""
              }`}
              id="shop-text"
            >
              SHOP{" "}
            </span>
          </a>

          {showDropdown && (
            <div
              className={`dropdown-container shadow ${
                isMobile ? "mobile-dropdown" : ""
              }`}
              style={{
                position: "absolute",
                top: "100%",
                left: 0,
                zIndex: 999,
                width: isMobile ? "auto" : "500px",
                background: "white",
              }}
            >
              <div className="text-left p-2">
                {isProductsLoading ? (
                  <div
                    style={{
                      height: "100px",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <p>
                      Loading... <LoadingOutlined />
                    </p>
                  </div>
                ) : (
                  <div className="shop-dropdown" style={{ color: "black" }}>
                    {Object.entries(filteredCategoriesMap).map(
                      ([mainCategory, subCategories]) => (
                        <div key={mainCategory} className="col m-2 mb-3">
                          <p
                            className="category m-1"
                            onClick={() => handleMenuClick(mainCategory, "")}
                            style={{ cursor: "pointer" }}
                          >
                            {mainCategory}
                          </p>
                          {subCategories.map(
                            (subCategory, subCategoryIndex) => (
                              <p
                                key={subCategoryIndex}
                                className="m-1 type"
                                onClick={() =>
                                  handleMenuClick(
                                    mainCategory,
                                    subCategory.type
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {subCategory.type}
                              </p>
                            )
                          )}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </li>

        <li
          className={`menu-option ${
            isActive(artworks_path) ? "selected-tab" : ""
          }`}
          onClick={() => navigate(artworks_path)}
        >
          GALLERY{" "}
        </li>

        <li
          className={`menu-option ${
            isActive(artist_path) ? "selected-tab" : ""
          }`}
          onClick={() => navigate(artist_path)}
        >
          BIO{" "}
        </li>
        <li
          className={`menu-option ${
            isActive(contact_path) ? "selected-tab" : ""
          }`}
          onClick={() => navigate(contact_path)}
        >
          CONTACT{" "}
        </li>
      </ul>
    </div>
  );
};
