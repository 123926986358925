import React from "react";
import AxiosInstance from "../../Config/axios";
import { add_to_cart_api, cart_path, login_path } from "../../Config/config";
import { useNavigate } from "react-router";
import {
  AllProductsType,
  CartItemsType,
  useApplicationContext,
} from "../../Context/app-context";
import { Button, Modal } from "antd";

interface AddToCartProps {
  productData: AllProductsType;
}

const AddToCart: React.FC<AddToCartProps> = ({ productData }) => {
  const accessToken = localStorage.getItem("accessToken");
  const { userData, getCartItems, cartItems } = useApplicationContext();
  const navigate = useNavigate();
  const handleAdd = () => {
    const filteredCartItem = cartItems.filter(
      (item) => item.product_id === productData.id
    );
    const cartQuantity = filteredCartItem[0]?.quantity;
    const stockQuantity = filteredCartItem[0]?.stock_quantity;

    if (filteredCartItem.length === 0 || cartQuantity < stockQuantity) {
      const storedCartData: CartItemsType[] = JSON.parse(
        localStorage.getItem("cartData") || "[]"
      );
      if (userData) {
        const formData = new FormData();
        formData.append("product_id", productData?.id.toString());
        formData.append("quantity", "1");
        AxiosInstance.post(add_to_cart_api, formData, {
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${accessToken}`,
          },
        })
          .then(async (res) => {
            const response = await res.data;
            if (response.status !== 200) {
              navigate(login_path);
              return;
            }

            getCartItems();

            navigate(cart_path);
          })
          .catch((err) => console.log(err));
      } else {
        // Check if the product is already in the cart
        const existingCartItem = storedCartData.find(
          (item) => item.product_id === productData?.id
        );

        if (existingCartItem) {
          // If the product is already in the cart, update its quantity
          existingCartItem.quantity += 1;
        } else {
          // Otherwise, add the product to the cart with quantity 1
          storedCartData.push({
            product_id: productData?.id,
            quantity: 1,
            name: productData?.name,
            price: productData?.price,
            description: productData?.description,
            stock_quantity: productData?.stock,
            imageURLs: productData?.imageURLs,
            transformed_name: productData?.transformed_name,
          });
        }

        // Save the updated cart data to local storage
        localStorage.setItem("cartData", JSON.stringify(storedCartData));
        navigate(cart_path);
        window.location.reload();
      }
    } else {
      Modal.warning({
        centered: true,
        content:
          "The quantity you've selected exceeds our current stock availability for this item. Please check back later.",
        footer: (
          <div className="d-flex justify-content-center">
            <Button
              className="primary-btn mt-3 "
              style={{ width: "100px" }}
              onClick={() => Modal.destroyAll()}
            >
              OK
            </Button>
          </div>
        ),
      });
    }
  };
  return (
    <div>
      {" "}
      <Button
        onClick={(e) => {
          e.stopPropagation();
          handleAdd();
        }}
        style={{ width: "200px" }}
        className="primary-btn"
      >
        Add to cart
      </Button>
    </div>
  );
};

export default AddToCart;
