// ForgotPassword.tsx
import React, { useState } from "react";
import { Form, Input, Button, notification, Modal } from "antd";
import NavBar from "../../Layout/nav-bar";
import AxiosInstance from "../../Config/axios";
import { check_user_api, login_path } from "../../Config/config";
import { useNavigate } from "react-router-dom";
import emailIcon from "../../Icons/email.gif";

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [ismailSent, setIsMailSent] = useState(false);
  const [credentialsErr, setCredentialsErr] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onFinish = async (values: { email: string }) => {
    setLoading(true);

    const validateResponse = await checkForExistingUser(values.email);

    if (validateResponse.status !== 200) {
      notification.error({
        message: "Error",
        description: "User not found",
      });
      setCredentialsErr(true);
      setLoading(false);
      return;
    }

    try {
      // Make API call to request password reset link
      await AxiosInstance.post("/api/users/forgot-password", values);

      setIsMailSent(true);
      form.resetFields(); // Resetting the form fields
    } catch (error) {
      notification.error({
        message: "Error",
        description: "There was an error sending the password reset link.",
      });
      setIsMailSent(false);
    } finally {
      setLoading(false);
    }
  };
  const checkForExistingUser = async (email: string) => {
    try {
      const res = await AxiosInstance.post(check_user_api, { email: email });
      const response = res.data;

      return response;
    } catch (err) {
      console.log(err);
      // Handle the error here or return an error response if needed
      throw err;
    }
  };

  const handleModalClose = () => {
    setIsMailSent(false);
    navigate(login_path);
  };
  return (
    <>
      <NavBar />

      <div className="main-container main-container-height mb-5 text-left">
        <h1 className=" page-header">Forgot Password</h1>
        <div>
          {" "}
          <div style={{ maxWidth: 500 }}>
            <Form
              name="forgot_password"
              form={form}
              onFinish={onFinish}
              layout="vertical"
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
                validateStatus={credentialsErr ? "error" : ""}
                help={credentialsErr ? "Invalid credentials" : ""}
              >
                <Input
                  // prefix={<MailOutlined />}
                  placeholder="Email"
                  className="text-start primary-input"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  loading={loading}
                  block
                  className="primary-btn"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <Modal
        visible={ismailSent}
        footer={null}
        centered
        onCancel={handleModalClose}
      >
        <div className="container p-3 d-flex-column align-items-center text-center">
          <img width={80} src={emailIcon} alt="" loading="lazy" />
          <p className="">Password reset link has been sent to your email.</p>
        </div>
      </Modal>
    </>
  );
};

export default ForgotPassword;
