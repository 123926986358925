import React, { Dispatch, SetStateAction, useState } from "react";
import NavBar from "../../Layout/nav-bar";
import FooterComponent from "../../Layout/footer";
import { Button, Form, Input } from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import AxiosInstance from "../../Config/axios";
import {
  check_user_api,
  domain,
  home_path,
  login_path,
  register_api,
  send_otp_api,
  verify_otp_api,
} from "../../Config/config";
import { useApplicationContext } from "../../Context/app-context";
import { Helmet } from "react-helmet";
import { MetaTags } from "../../Utils/helperFunction";

interface CreateAccountProps {
  setIsOtpSent: Dispatch<SetStateAction<boolean>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setFirstName: Dispatch<SetStateAction<string>>;
  setLastName: Dispatch<SetStateAction<string>>;
  setPassword: Dispatch<SetStateAction<string>>;
  email: string;
  password: string;
}

interface OtpVerificationProps {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

const CreateAccount: React.FC<CreateAccountProps> = ({
  setIsOtpSent,
  setEmail,
  email,
  setFirstName,
  setLastName,
  setPassword,
  password,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUserExists, setIsUserExists] = useState(false);
  const [passwordCondition, setPasswordCondition] = useState(true);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);

    const validateResponse = await checkForExistingUser();

    if (validateResponse.status === 200) {
      setIsUserExists(true);
      setIsLoading(false);
      return;
    }

    const lowerCasedEmail = email?.toLowerCase();

    AxiosInstance.post(send_otp_api, {
      email: lowerCasedEmail,
    })
      .then(async (response) => {
        setIsOtpSent(true);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const checkForExistingUser = async () => {
    try {
      const res = await AxiosInstance.post(check_user_api, { email: email });
      const response = res.data;

      return response;
    } catch (err) {
      console.log(err);
      // Handle the error here or return an error response if needed
      throw err;
    }
  };

  const passwordLengthValidation = () => {
    if (password?.length > 0) {
      if (password.length < 8) {
        setPasswordCondition(false);
      } else {
        setPasswordCondition(true);
      }
    } else {
      setPasswordCondition(true);
    }
  };
  const [form] = Form.useForm();
  return (
    <div className="d-lg-flex">
      <div className=" text-left main-container main-container-height col-lg-6">
        <p className=" page-header">CREATE ACCOUNT</p>
        <div className=" p-0">
          <Form form={form} onFinish={handleSubmit} className="php-email-form">
            <div className="d-lg-flex">
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter at least 4 characters",
                    min: 4,
                  },
                ]}
                className="col-lg-6 pl-0 pr-0 pr-lg-3  form-group"
              >
                <Input
                  placeholder="First name"
                  onChange={(e) => setFirstName(e.target.value)}
                  className="primary-input"
                />
              </Form.Item>
              <Form.Item
                name="lastName"
                rules={[
                  { required: true, message: "Please enter a valid last name" },
                ]}
                className="col-lg-6 p-0 form-group"
              >
                <Input
                  placeholder="Last name"
                  onChange={(e) => setLastName(e.target.value)}
                  className="primary-input"
                />
              </Form.Item>
            </div>

            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid email",
                  type: "email",
                },
              ]}
              validateStatus={isUserExists ? "error" : ""}
              className="col p-0 form-group"
            >
              <Input
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                className="primary-input"
              />

              {isUserExists && (
                <p
                  className="text-danger m-0 text-leftx "
                  style={{ fontSize: "14px" }}
                >
                  User already exists
                </p>
              )}
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your new password!" },
                {
                  min: 8,
                  message: "Password must be at least 8 characters long!",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                placeholder="Password"
                className="text-start primary-input"
              />
            </Form.Item>

            <div className="text-left ">
              <Button
                htmlType="submit"
                disabled={!passwordCondition}
                style={{ transition: "all ease 1s" }}
                className="primary-btn mt-3"
              >
                {isLoading ? <LoadingOutlined /> : "Create an account"}
              </Button>
              <p className="card-text text-muted mt-3">
                Remember your password?
                <span
                  className="text-dark"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/login")}
                >
                  {" "}
                  Login
                </span>
              </p>
            </div>
          </Form>
        </div>
      </div>
      <div
        className="col-lg-6 d-none d-lg-block"
        style={{
          backgroundImage:
            "url(https://res.cloudinary.com/dsw1ubwyh/image/upload/v1713980814/BLACK_and_White_DOODLE_1_lfu8tv.jpg)",

          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
    </div>
  );
};

const OtpVerification: React.FC<OtpVerificationProps> = ({
  email,
  firstName,
  lastName,
  password,
}) => {
  const [warning, setWarning] = useState("");
  const [otpPin, setOtpPin] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const navigate = useNavigate();
  const { setUserData } = useApplicationContext();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();

    AxiosInstance.post(verify_otp_api, {
      email: email,
      otp: otpPin,
    })
      .then(async (res) => {
        const response = await res.data;
        setIsLoading(false);

        if (response.status !== 200) {
          setWarning("Invalid OTP");
          return;
        }

        setOtpVerified(true);
        createAccount();
      })
      .catch((err) => {
        setIsLoading(false);
        setWarning("Invalid OTP");

        console.log(err);
      });
  };

  const createAccount = () => {
    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("password", password);

    AxiosInstance.post(register_api, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.data;

        const accessToken = data.accessToken;

        if (!accessToken || data.status !== 200) {
          navigate(login_path);

          return;
        }
        localStorage.setItem("accessToken", accessToken);
        setUserData(data);
        navigate(home_path);
      })
      .catch((err) => {
        console.log(err);
        alert("something is wrong");
        navigate(login_path);
      });
  };

  return (
    <div className="container mb-5  text-left ">
      {otpVerified ? (
        <div style={{ height: "40vh" }}>
          <p className="my-5 page-header  ">CREATING ACCOUNT</p>
          <div>
            Loading.....
            <LoadingOutlined />
          </div>
        </div>
      ) : (
        <div className="col-lg-6">
          <p className="my-5 page-header ">VERIFICATION</p>
          <div>
            <h3>Enter Verification Code</h3>
          </div>
          <p style={{ width: "80%" }}>
            Please type in the <span>6-digit code</span>
            sent to your email. If it does not appear in your Inbox, please
            check your Updates, Quarantined or Spam folders.
          </p>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <Input
                type="text"
                placeholder="OTP"
                onChange={(e) => setOtpPin(e.target.value)}
                className="primary-input"
                required
              />
            </div>

            <p style={{ color: "red" }}>{warning}</p>
            <br />
            <Button className="btn btn-dark w-100" htmlType="submit">
              {isLoading ? <LoadingOutlined /> : "Verify"}
            </Button>
          </form>
        </div>
      )}
    </div>
  );
};

const Register = () => {
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  return (
    <>
      <MetaTags
        title="Risheet Register"
        description=""
        robots="index, nofollow"
        schema={null}
      />

      <NavBar />
      <div>
        {isOtpSent ? (
          <OtpVerification
            email={email}
            firstName={firstName}
            lastName={lastName}
            password={password}
          />
        ) : (
          <CreateAccount
            setIsOtpSent={setIsOtpSent}
            setEmail={setEmail}
            email={email}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setPassword={setPassword}
            password={password}
          />
        )}
      </div>
      <FooterComponent />
    </>
  );
};

export default Register;
