import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  account_path,
  admin_dashboard_path,
  artist_path,
  artworks_path,
  cart_checkout_path,
  cart_path,
  contact_path,
  forgot_password_path,
  home_path,
  login_path,
  product_collection_path,
  register_path,
  reset_password_path,
  shipping_path,
  user_order_path,
  wishlist_path,
} from "../Config/config";
import HomePage from "../Components/Home";
import AdminDashboard from "../Components/AdminDashboard";
import ProductPage from "../Components/Product";
import ArtistPage from "../Components/Bio";
import ArtWorksPage from "../Components/Gallery";
import Login from "../Components/Auth/login";
import Register from "../Components/Auth/register";
import CartPage from "../Components/Cart/cart-page";
import ShippingPage from "../Payment/shipping";
import Contact from "../Components/Contact";
import PageNotFound from "../Components/Misc/page-not-found";
import MyOrders from "../Components/Orders/my-orders";
import MyDetails from "../Components/UserDetails/my-details";
import Wishlist from "../Components/Wishlist/wishlist-page";
import { CartCheckoutPage } from "../Components/Cart/cart-checkout";
import ForgotPassword from "../Components/Auth/forgotPassword";
import ResetPassword from "../Components/Auth/resetPassword";
import Categories from "../Components/Home/categories";
import ShopComponent from "../Components/Shop";
// import EditProductImages from "../Utils/test";

import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  return null;
};

const AppRoute: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path={home_path} element={<HomePage />} />
        <Route path={admin_dashboard_path} element={<AdminDashboard />} />
        <Route
          path={`${product_collection_path}/:productName`}
          element={<ProductPage />}
        />
        <Route path={artist_path} element={<ArtistPage />} />
        <Route path={artworks_path} element={<ArtWorksPage />} />
        <Route path={product_collection_path} element={<ShopComponent />} />
        <Route path={login_path} element={<Login />} />
        <Route path={register_path} element={<Register />} />
        <Route path={cart_path} element={<CartPage />} />
        <Route path={shipping_path} element={<ShippingPage />} />
        <Route path={contact_path} element={<Contact />} />
        <Route path={user_order_path} element={<MyOrders />} />
        <Route path={account_path} element={<MyDetails />} />
        <Route path={wishlist_path} element={<Wishlist />} />
        <Route path={cart_checkout_path} element={<CartCheckoutPage />} />
        <Route path={forgot_password_path} element={<ForgotPassword />} />
        <Route path={reset_password_path} element={<ResetPassword />} />
        {/* <Route path={"/test"} element={<EditProductImages productId={1} />} /> */}

        <Route path={"*"} element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;
