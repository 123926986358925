import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { getISOByParam, getAllInfoByISO } from "iso-country-currency";
import AxiosInstance from "../Config/axios";
import {
  get_address_api,
  get_all_products_api,
  get_cart_items_api,
  get_product_categories_api,
  get_product_types_categories_api,
  get_user_data,
  get_wishlist_items_api,
} from "../Config/config";
import axios from "axios";

export interface AllProductsType {
  id: number;
  name: string;
  price: string;
  type: string;
  description: string;
  dimension: string;
  category: string;
  stock: number;
  artwork: string;
  size: string;
  colour: string;
  imageURLs: Array<string>;
  transformed_name: string;
}

export interface ProductTypes {
  type_id: number;
  type: string;
  category_id: number;
  category: string;
}

export interface ProductCategoryType {
  id: number;
  name: string;
  image_url: string;
}

export interface CartItemsType {
  product_id: number;
  quantity: number;
  name: string;
  price: string;
  description: string;
  stock_quantity: number;
  imageURLs: Array<string>;
  transformed_name: string;
}
export interface WishlistItemsType {
  product_id: number;
  quantity: number;
  name: string;
  price: string;
  description: string;
  stock_quantity: number;
  imageURLs: Array<string>;
  transformed_name: string;
}

interface AppContextProps {
  userData: any;
  setUserData: Dispatch<SetStateAction<any>>;
  allProducts: AllProductsType[];
  setAllProducts: Dispatch<SetStateAction<AllProductsType[]>>;
  productTypes: ProductTypes[];
  setIsMobile: Dispatch<SetStateAction<boolean>>;
  isMobile: boolean;
  setIsProductCreated: Dispatch<SetStateAction<boolean>>;
  isProductCreated: boolean;
  setCartItems: Dispatch<SetStateAction<CartItemsType[]>>;
  cartItems: CartItemsType[];
  wishlistItems: WishlistItemsType[];
  setIsCartLoading: Dispatch<SetStateAction<boolean>>;
  isCartLoading: boolean;
  setIsProductsLoading: Dispatch<SetStateAction<boolean>>;
  isCurrencyLoading: boolean;
  isProductsLoading: boolean;
  currencyCode: string;
  currencySymbol: string;
  getConvertedPrice: (price: string) => string;
  getWishlistItems: () => void;
  getCartItems: () => void;
  getAddresses: () => void;
  getUserData: () => void;
  productCategories: ProductCategoryType[];
  userAddresses: any[];
  filter: { category: string; type: string };
  setFilter: React.Dispatch<
    React.SetStateAction<{ category: string; type: string }>
  >;
}

const MyContext = createContext<AppContextProps | undefined>(undefined);

interface AppContextProviderProps {
  children: ReactNode;
}
interface ConversionRates {
  [currency: string]: number;
}

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
}: AppContextProviderProps) => {
  const [userData, setUserData] = useState(null);
  const [allProducts, setAllProducts] = useState<AllProductsType[]>([]);
  const [productTypes, setProductTypes] = useState<ProductTypes[]>([]);
  const [isProductCreated, setIsProductCreated] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [cartItems, setCartItems] = useState<CartItemsType[]>([]);
  const [wishlistItems, setWishlistItems] = useState<WishlistItemsType[]>([]);
  const storedCartData = JSON.parse(localStorage.getItem("cartData") || "[]");
  const [isCartLoading, setIsCartLoading] = useState<boolean>(false);
  const [isProductsLoading, setIsProductsLoading] = useState<boolean>(false);
  const [isCurrencyLoading, setIsCurrencyLoading] = useState<boolean>(false);
  const [currencyCode, setCurrencyCode] = useState<string>("AUD");
  const [currencySymbol, setCurrencySymbol] = useState<string>("$");
  const [conversionRates, setConversionRates] = useState<ConversionRates>({}); // State to store conversion rates
  const [productCategories, setProductCategories] = useState<
    ProductCategoryType[]
  >([]);
  const [userAddresses, setUserAddresses] = useState<any[]>([]);
  const [filter, setFilter] = useState<{ category: string; type: string }>({
    category: "",
    type: "",
  });
  const accessToken = localStorage.getItem("accessToken");
  useEffect(() => {
    getUserData();
    //eslint-disable-next-line
  }, [accessToken]);

  useEffect(() => {
    setIsProductsLoading(true);
    AxiosInstance.get(get_all_products_api)
      .then(async (res) => {
        const response = await res.data;
        if (response.status === 200) {
          setAllProducts(response.data);
          setIsProductsLoading(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setIsProductsLoading(true);
    AxiosInstance.get(get_product_types_categories_api)
      .then(async (res) => {
        const response = await res.data;
        if (response.status === 200) {
          setProductTypes(response.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    AxiosInstance.get(get_product_categories_api)
      .then(async (res) => {
        const response = await res.data;
        if (response.status === 200) {
          setProductCategories(response.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (userData) {
      setIsCartLoading(true);
      getCartItems();
    } else {
      setCartItems(storedCartData);
    }
    //eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    getWishlistItems();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Fetch user's IP address
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        const ipAddress = data.ip;

        // Use IP Geolocation API to get country
        fetch(`https://ipapi.co/${ipAddress}/json/`)
          .then((response) => response.json())
          .then((data) => {
            const country = data.country_name;

            // Map country to currency code
            const ISOCode = getISOByParam("countryName", country);
            const ISOData = getAllInfoByISO(ISOCode);

            setCurrencyCode(ISOData.currency);
            setCurrencySymbol(ISOData.symbol);
            setIsCurrencyLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching country:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });
  }, []);

  const fetchConversionRates = async () => {
    try {
      // Fetch conversion rates from an API (example: https://api.exchangerate-api.com)
      const response = await axios.get(
        "https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_niPrfQrjCVSmmaDdAt3UYN7vFZq5pnLNHQQyoa28&currencies=&base_currency=AUD"
        // "https://v6.exchangerate-api.com/v6/7ecf5f96201e597d610bd1ec/latest/AUD"
      );
      const rates = response.data.data;
      setConversionRates(rates);
    } catch (error) {
      console.error("Error fetching conversion rates:", error);
    }
  };

  useEffect(() => {
    setIsCurrencyLoading(true);
    fetchConversionRates(); // Fetch conversion rates on component mount
  }, []);

  const getConvertedPrice = (price: string) => {
    if (!(conversionRates[currencyCode] * parseFloat(price))) {
      setCurrencyCode("AUD");
      setCurrencySymbol("$");
    }
    return (
      conversionRates[currencyCode] * parseFloat(price) || parseFloat(price)
    ).toFixed(2);
  };
  const getUserData = () => {
    AxiosInstance.get(
      get_user_data,

      {
        headers: {
          "Content-Type": "application/json",
          token: `Bearer ${accessToken}`,
        },
      }
    )
      .then((res) => {
        const UserData = res.data?.data;
        if (res.status === 200) {
          setUserData(UserData);
        }
      })
      .catch((err) => console.log(err));
  };
  const getWishlistItems = () => {
    AxiosInstance.get(
      get_wishlist_items_api,

      {
        headers: {
          "Content-Type": "application/json",
          token: `Bearer ${accessToken}`,
        },
      }
    )
      .then((res) => {
        const response = res.data;

        setWishlistItems(response.data);
      })

      .catch((err) => console.log(err));
  };

  const getCartItems = () => {
    AxiosInstance.get(
      get_cart_items_api,

      {
        headers: {
          "Content-Type": "application/json",
          token: `Bearer ${accessToken}`,
        },
      }
    )
      .then((res) => {
        const response = res.data;
        setIsCartLoading(false);
        setCartItems(response.data);
      })

      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAddresses();
    //eslint-disable-next-line
  }, []);

  const getAddresses = () => {
    AxiosInstance.get(
      get_address_api,

      {
        headers: {
          "Content-Type": "application/json",
          token: `Bearer ${accessToken}`,
        },
      }
    )
      .then((res) => {
        const response = res.data;

        setUserAddresses(response.data);
      })

      .catch((err) => console.log(err));
  };

  const value: AppContextProps = {
    allProducts,
    setAllProducts,
    productTypes,
    isMobile,
    setIsMobile,
    userData,
    setUserData,
    isProductCreated,
    setIsProductCreated,
    setCartItems,
    cartItems,
    setIsCartLoading,
    isCartLoading,
    isProductsLoading,
    setIsProductsLoading,
    currencyCode,
    currencySymbol,
    getConvertedPrice,
    isCurrencyLoading,
    productCategories,
    wishlistItems,
    getWishlistItems,
    getCartItems,
    getAddresses,
    getUserData,
    userAddresses,
    filter,
    setFilter,
  };

  useEffect(() => {
    // Check if the screen width is less than a certain value (e.g., 768px) to determine if it's a mobile device
    const handleResize = () => {
      setIsMobile(window.innerWidth < 913);
    };

    // Add an event listener to handle window resizing
    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <MyContext.Provider value={value}>{children}</MyContext.Provider>;
};

export const useApplicationContext = (): AppContextProps => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error(
      "useApplicationContext must be used within an AppContextProvider"
    );
  }
  return context;
};
