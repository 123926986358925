import React, { useState } from "react";
import { Divider, Collapse } from "antd";
import { logoColors } from "../Config/config";

interface Category {
  name: string;
  types: string[];
}

interface FilterSidebarProps {
  categories: Category[];
  onFilterChange: (filters: { category: string; type: string }) => void;
}

const { Panel } = Collapse;

const FilterSidebar: React.FC<FilterSidebarProps> = ({
  categories,
  onFilterChange,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("");

  const handleCategoryChange = (category: string) => {
    if (selectedCategory === category) {
      setSelectedCategory("");
      setSelectedType("");
    } else {
      setSelectedCategory(category);
      setSelectedType("");
    }
    onFilterChange({ category, type: "" });
  };

  const handleTypeChange = (type: string) => {
    setSelectedType(type);
    onFilterChange({ category: selectedCategory, type });
  };

  const clearCategoryFilter = () => {
    setSelectedCategory("");
    setSelectedType("");
    onFilterChange({ category: "", type: "" });
  };

  return (
    <div className="filter-sidebar">
      <div className="filter-section">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0">Categories</h4>
          {selectedCategory && (
            <p
              onClick={clearCategoryFilter}
              className="m-0 color-primary"
              style={{ cursor: "pointer" }}
            >
              Clear
            </p>
          )}
        </div>

        <Divider />

        <Collapse
          bordered={false}
          activeKey={selectedCategory ? [selectedCategory] : undefined}
          expandIcon={() => null}
          style={{ background: "transparent", border: 0 }}
        >
          {categories.map((category) => (
            <Panel
              key={category.name}
              header={
                <div
                  onClick={() => handleCategoryChange(category.name)}
                  style={{
                    cursor: "pointer",
                    paddingLeft: 0,
                    fontWeight:
                      selectedCategory === category.name ? "bold" : "normal",
                  }}
                >
                  {category.name}
                </div>
              }
              style={{ background: "transparent", border: 0 }}
            >
              <ul style={{ paddingLeft: 0 }}>
                {category.types.map((type) => (
                  <li
                    key={type}
                    onClick={() => handleTypeChange(type)}
                    style={{
                      cursor: "pointer",
                      fontWeight: type === selectedType ? "bold" : "normal",
                      color: type === selectedType ? logoColors[2] : "black",
                      paddingLeft: 0,
                    }}
                  >
                    {type}
                  </li>
                ))}
              </ul>
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default FilterSidebar;
