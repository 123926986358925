import React, { useEffect, useState } from "react";
import { Button, Form, Input, InputNumber, Select, message } from "antd";
import AxiosInstance from "../../Config/axios";
import ImagesUpload from "./image-upload";
import { useApplicationContext } from "../../Context/app-context";
import { LoadingOutlined } from "@ant-design/icons";
import { login_path } from "../../Config/config";
import { useNavigate } from "react-router-dom";
import { ProductAttributes, ProductTypesOptionType } from ".";

const { Option } = Select;

interface CreateProductFormProps {
  productTypesOptions: ProductTypesOptionType[];
  productArtworks: ProductAttributes[];
  productSizes: ProductAttributes[];
  mainCategoryOption: ProductAttributes[];
  productColors: ProductAttributes[];
  setTrigger: (trigger: boolean) => void;
  trigger: boolean;
}
export interface ColorOptionType {
  id: number;
  color: string;
}

interface SubCategoryType {
  typeId: number;
  type: string;
}

const CreateProductForm: React.FC<CreateProductFormProps> = ({
  productTypesOptions,
  setTrigger,
  trigger,
  productArtworks,
  productSizes,
  mainCategoryOption,
  productColors,
}) => {
  const [form] = Form.useForm();
  const { setIsProductCreated } = useApplicationContext();
  const [subCategoryOption, setSubCategoryOption] = useState<SubCategoryType[]>(
    []
  );
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isArtworkAdd, setIsArtworkAdd] = useState<boolean>(false);
  const [isColorAdd, setIsColorAdd] = useState<boolean>(false);
  const [isCategoryAdd, setIsCategoryAdd] = useState<boolean>(false);
  const [isTypeAdd, setIsTypeAdd] = useState<boolean>(false);
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedCategory) {
      const filtered = productTypesOptions.filter(
        (item) => item.category_id === parseInt(selectedCategory)
      );

      const subCategories = filtered.map((item) => ({
        type: item.name,
        typeId: item.id,
      }));

      const uniqueTypesWithId = subCategories.filter(
        (type, index, self) =>
          index === self.findIndex((c) => c.typeId === type.typeId)
      );

      setSubCategoryOption(uniqueTypesWithId);
    }
  }, [selectedCategory, productTypesOptions]);

  const onFinish = (values: any) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("productName", values.productName);
    formData.append("price", values.price);
    formData.append("type", values.type);
    formData.append("dimension", values.dimension ? values.dimension : "");
    formData.append("size", values.size ? values.size : null);
    formData.append("art", values.art);
    formData.append("color", values.color ? values.color : null);
    formData.append("description", values.description);
    formData.append("stockQuantity", values.stockQuantity);

    values.images.fileList.forEach((file: any) => {
      formData.append("images", file.originFileObj); // Append the file's originFileObj
    });
    AxiosInstance.post("api/admin/create-product", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        token: `Bearer ${accessToken}`,
      },
    })
      .then(async (res) => {
        setIsLoading(false);
        const response = await res.data;

        if (response.status === 403 || response.status === 401) {
          alert(response.message);
          navigate(login_path);
        }

        if (response.status === 200) {
          setIsProductCreated(true);
          form.resetFields(); // Reset the form fields
          message.success("Product created successfully!");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCategoryChange = (value: string) => {
    setSelectedCategory(value);

    // Clear the type field
    form.setFieldsValue({
      type: undefined,
    });
  };

  const handleArtworkAdd = () => {
    if (isArtworkAdd) {
      AxiosInstance.post(
        "api/admin/create-artwork",
        { artwork: form.getFieldValue("artwork-add") },
        {
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${accessToken}`,
          },
        }
      )
        .then(async (res) => {
          const response = await res.data;

          if (response.status === 403 || response.status === 401) {
            alert(response.message);
            navigate(login_path);
          }

          if (response.status === 200) {
            setIsArtworkAdd(false);
            form.setFieldsValue({
              "artwork-add": undefined,
            });
            setTrigger(!trigger);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setIsArtworkAdd(true);
    }
  };
  const handleColorAdd = () => {
    if (isColorAdd) {
      AxiosInstance.post(
        "api/admin/create-color",
        { color: form.getFieldValue("color-add") },
        {
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${accessToken}`,
          },
        }
      )
        .then(async (res) => {
          const response = await res.data;

          if (response.status === 403 || response.status === 401) {
            alert(response.message);
            navigate(login_path);
          }

          if (response.status === 200) {
            setIsColorAdd(false);
            form.setFieldsValue({
              "color-add": undefined,
            });
            setTrigger(!trigger);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setIsColorAdd(true);
    }
  };
  const handleCategoryAdd = () => {
    if (isCategoryAdd) {
      AxiosInstance.post(
        "api/admin/create-category",
        { category: form.getFieldValue("category-add") },
        {
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${accessToken}`,
          },
        }
      )
        .then(async (res) => {
          const response = await res.data;

          if (response.status === 403 || response.status === 401) {
            alert(response.message);
            navigate(login_path);
          }

          if (response.status === 200) {
            setIsCategoryAdd(false);
            form.setFieldsValue({
              "category-add": undefined,
            });
            setTrigger(!trigger);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setIsCategoryAdd(true);
    }
  };
  const handleTypeAdd = () => {
    if (isTypeAdd && selectedCategory) {
      AxiosInstance.post(
        "api/admin/create-type",
        { type: form.getFieldValue("type-add"), categoryId: selectedCategory },
        {
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${accessToken}`,
          },
        }
      )
        .then(async (res) => {
          const response = await res.data;

          if (response.status === 403 || response.status === 401) {
            alert(response.message);
            navigate(login_path);
          }

          if (response.status === 200) {
            setIsTypeAdd(false);
            form.setFieldsValue({
              "type-add": undefined,
            });
            setTrigger(!trigger);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setIsTypeAdd(true);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      // onValuesChange={handleFormValuesChange}
    >
      <div className="row">
        <Form.Item
          name="productName"
          label="Product Name"
          rules={[{ required: true, message: "Please enter product name" }]}
          className="col-lg-6"
        >
          <Input
            style={{ borderRadius: 0 }}
            placeholder="Enter"
            className="primary-input"
          />
        </Form.Item>

        <Form.Item
          name="price"
          label="Price"
          rules={[
            {
              required: true,
              type: "number",
              min: 0,
              message: "Please enter a valid price",
            },
          ]}
          className="col-lg-6"
        >
          <InputNumber
            style={{ width: "100%", borderRadius: "0" }}
            formatter={(value: string | undefined) =>
              `AU$ ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value: string | undefined) =>
              value ? value.replace(/AU\$\s?|(,*)/g, "") : ""
            }
            className="primary-input"
          />
        </Form.Item>
      </div>
      <div className="row">
        <div className="col-lg-6 " style={{ marginBottom: "24px" }}>
          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true, message: "Please select category" }]}
            className="m-0"
          >
            <Select
              className="primary-input"
              onChange={handleCategoryChange}
              style={{ borderRadius: 0 }}
              placeholder="Select"
            >
              {" "}
              {mainCategoryOption.map((c) => (
                <Option key={c.id} value={c.id}>
                  {c.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className="text-left mt-2">
            {isCategoryAdd && (
              <Form.Item name="category-add" className="mb-2">
                <Input
                  style={{ borderRadius: 0 }}
                  placeholder="Enter"
                  className="primary-input"
                />
              </Form.Item>
            )}

            <button
              onClick={(e) => {
                e.preventDefault();
                handleCategoryAdd();
              }}
              className=" btn border py-1 px-2 btn-dark"
              style={{ fontSize: "12px" }}
            >
              {isCategoryAdd ? "Save" : "Add category"}
            </button>

            {isCategoryAdd && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setIsCategoryAdd(false);
                }}
                className="btn border py-1 ml-2 px-2 btn-dark"
                style={{ fontSize: "12px" }}
              >
                Cancel
              </button>
            )}
          </div>
        </div>

        <div className="col-lg-6" style={{ marginBottom: "24px" }}>
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: "Please select type" }]}
            className="m-0"
          >
            <Select
              className="primary-input"
              disabled={!selectedCategory}
              style={{ borderRadius: 0 }}
              placeholder="Select"
            >
              {" "}
              {subCategoryOption.map((c) => (
                <Option key={c.typeId} value={c.typeId}>
                  {c.type}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className="text-left mt-2">
            {isTypeAdd && (
              <div>
                <Form.Item name="type-add" className="mb-2">
                  <Input
                    style={{ borderRadius: 0 }}
                    placeholder="Enter"
                    className="primary-input"
                  />
                </Form.Item>
                <p className="text-danger " style={{ fontSize: "10px" }}>
                  Before saving the type value make sure you have selected the
                  correct category value in the category input
                </p>
              </div>
            )}

            <button
              onClick={(e) => {
                e.preventDefault();
                handleTypeAdd();
              }}
              disabled={!selectedCategory}
              className=" btn border py-1 px-2 btn-dark"
              style={{ fontSize: "12px" }}
            >
              {isTypeAdd ? "Save" : "Add type"}
            </button>

            {isTypeAdd && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setIsTypeAdd(false);
                }}
                className="btn border py-1 ml-2 px-2 btn-dark"
                style={{ fontSize: "12px" }}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <Form.Item name="dimension" label="Dimension" className="col-lg-6">
          <Input
            style={{ borderRadius: 0 }}
            placeholder="Enter"
            className="primary-input"
          />
        </Form.Item>
        <Form.Item name="size" label="Size" className="col-lg-6">
          <Select
            className="primary-input"
            style={{ borderRadius: 0 }}
            placeholder="Select"
          >
            {" "}
            {productSizes.map((c) => (
              <Option key={c.id} value={c.id}>
                {c.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className="row">
        <div className="col-lg-6" style={{ marginBottom: "24px" }}>
          <Form.Item
            name="art"
            label="Art work"
            rules={[{ required: true, message: "Please select art" }]}
            className="m-0"
          >
            <Select
              className="primary-input"
              style={{ borderRadius: 0 }}
              placeholder="Select"
            >
              {" "}
              {productArtworks.map((c) => (
                <Option key={c.id} value={c.id}>
                  {c.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className="text-left mt-2">
            {isArtworkAdd && (
              <Form.Item name="artwork-add" className="mb-2">
                <Input
                  style={{ borderRadius: 0 }}
                  placeholder="Enter"
                  className="primary-input"
                />
              </Form.Item>
            )}

            <button
              onClick={(e) => {
                e.preventDefault();
                handleArtworkAdd();
              }}
              className=" btn border py-1 px-2 btn-dark"
              style={{ fontSize: "12px" }}
            >
              {isArtworkAdd ? "Save" : "Add art work"}
            </button>

            {isArtworkAdd && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setIsArtworkAdd(false);
                }}
                className="btn border py-1 ml-2 px-2 btn-dark"
                style={{ fontSize: "12px" }}
              >
                Cancel
              </button>
            )}
          </div>
        </div>

        <div className="col-lg-6" style={{ marginBottom: "24px" }}>
          <Form.Item name="color" label="Colour" className="m-0">
            <Select
              className="primary-input"
              style={{ borderRadius: 0 }}
              placeholder="Select"
            >
              {" "}
              {productColors.map((c) => (
                <Option key={c.id} value={c.id}>
                  {c.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className="text-left mt-2">
            {isColorAdd && (
              <Form.Item name="color-add" className="mb-2">
                <Input
                  style={{ borderRadius: 0 }}
                  placeholder="Enter"
                  className="primary-input"
                />
              </Form.Item>
            )}

            <button
              onClick={(e) => {
                e.preventDefault();
                handleColorAdd();
              }}
              className=" btn border py-1 px-2 btn-dark"
              style={{ fontSize: "12px" }}
            >
              {isColorAdd ? "Save" : "Add colour"}
            </button>

            {isColorAdd && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setIsColorAdd(false);
                }}
                className="btn border py-1 ml-2 px-2 btn-dark"
                style={{ fontSize: "12px" }}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>

      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: "Please enter description" }]}
      >
        <Input.TextArea
          className="primary-textarea"
          rows={8}
          style={{ borderRadius: 0 }}
          placeholder="Enter"
        />
      </Form.Item>

      <Form.Item
        name="stockQuantity"
        label="Stock Quantity"
        rules={[
          {
            required: true,
            type: "number",
            min: 0,
            message: "Please enter a valid stock quantity",
          },
        ]}
      >
        <InputNumber
          style={{ width: "100%", borderRadius: "0" }}
          className="primary-input"
        />
      </Form.Item>

      <ImagesUpload />

      <Form.Item>
        <Button htmlType="submit" className="primary-btn">
          {isLoading ? (
            <span>
              Create <LoadingOutlined />
            </span>
          ) : (
            "Create"
          )}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateProductForm;
