import React, { useEffect, useState } from "react";
import NavBar from "../../Layout/nav-bar";
import FooterComponent from "../../Layout/footer";
import { useApplicationContext } from "../../Context/app-context";
import {
  DeleteOutlined,
  EditOutlined,
  HomeOutlined,
  PlusSquareOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Divider, Modal, Tag, Input, Form } from "antd";
import { AddressFormComponent } from "../Cart/cart-checkout";
import AxiosInstance from "../../Config/axios";
import { login_path } from "../../Config/config";
import { useNavigate } from "react-router-dom";

const MyDetails = () => {
  const { userData, isMobile, userAddresses, getUserData, getAddresses } =
    useApplicationContext();
  const [open, setOpen] = useState(false);
  const [addAddressOpen, setAddAddressOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [editField, setEditField] = useState("");
  const accessToken = localStorage.getItem("accessToken");
  const [form] = Form.useForm();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData) {
      navigate(login_path);
    }
  });

  const showModal = (field: any, value1: any, value2: any) => {
    setEditField(field);
    setInputValue(value1);
    setInputValue2(value2);
    setOpen(true);
  };

  const showAddAddressModal = () => {
    setAddAddressOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setAddAddressOpen(false);
  };

  const handleSave = () => {
    let apiUrl;
    switch (editField) {
      case "name":
        setIsSaveDisabled(false);
        apiUrl = "/api/users/update-name";
        break;
      case "email":
        setIsSaveDisabled(false);
        apiUrl = "/api/users/update-email";
        break;
      case "password":
        apiUrl = "/api/users/update-password";
        break;
      default:
        apiUrl = ""; // Set default API endpoint or handle error case
    }

    // Call the corresponding update API based on editField and pass inputValue
    // Upon successful API call, close the modal
    if (apiUrl) {
      AxiosInstance.post(
        apiUrl,
        { value: inputValue, value2: inputValue2 },
        {
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${accessToken}`,
          },
        }
      ) // Assuming API accepts JSON body with { value: inputValue }
        .then(async (res) => {
          const response = await res.data;
          if (response.status === 200) {
            // alert(`User ${editField} updated successfully`);
            getUserData();
            setOpen(false); // Close the modal
          }
        })
        .catch((error) => {
          // Handle error
          console.error("Error updating user info:", error);
          // Optionally, display error message to the user
        });
    } else {
      console.error("Invalid API endpoint for editing:", editField);
      // Optionally, display error message to the user
    }
  };
  const handleDeleteAddress = (addressId: number) => {
    // Call your API to delete the address using the addressId
    AxiosInstance.post(
      `/api/users/delete-address`,
      { id: addressId },
      {
        headers: {
          "Content-Type": "application/json",
          token: `Bearer ${accessToken}`,
        },
      }
    )
      .then(async (res) => {
        const response = await res.data;
        if (response.status === 200) {
          console.log("Address deleted successfully:", response.data);
        }

        // Refresh the list of addresses after deletion
        getAddresses();
      })
      .catch((error) => {
        // Handle error if necessary
        console.error("Error deleting address:", error);
      });
  };

  const handleFieldsChange = () => {
    form
      .validateFields()
      .then(() => {
        setIsSaveDisabled(false);
      })
      .catch(() => {
        setIsSaveDisabled(true);
      });
  };

  return (
    <div>
      <NavBar />
      <div className="main-container main-container-height text-left mb-5">
        <p className=" page-header">My Details</p>

        <div className="">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center ">
              <UserOutlined className="mr-3" />

              <h5 className="m-0">
                {userData?.first_name} {userData?.last_name}
              </h5>
            </div>
            <p
              className="m-0"
              onClick={() =>
                showModal(
                  "name",
                  `${userData?.first_name}`,
                  `${userData?.last_name}`
                )
              }
              style={{ cursor: "pointer" }}
            >
              {" "}
              {isMobile ? <EditOutlined /> : "Change "}{" "}
            </p>
          </div>
          <Divider />

          {/* Email */}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p style={{ margin: "0" }}>Email : {userData?.email}</p>
            <p
              className="m-0"
              onClick={() => showModal("email", userData?.email, "")}
              style={{ cursor: "pointer" }}
            >
              {isMobile ? <EditOutlined /> : "Change "}
            </p>
          </div>

          {/* Password */}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p style={{ margin: "0" }}> Password : ********</p>

            <p
              className="m-0"
              onClick={() => showModal("password", "", "")}
              style={{ cursor: "pointer" }}
            >
              {isMobile ? <EditOutlined /> : "Change "}
            </p>
          </div>
          <Divider />

          {/* Address Section */}
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center ">
              <HomeOutlined className="mr-3" />

              <h5 className="m-0">Address</h5>
            </div>
            <p
              style={{ cursor: "pointer" }}
              className="m-0"
              onClick={showAddAddressModal}
            >
              {" "}
              {isMobile ? <PlusSquareOutlined /> : "Add "}{" "}
            </p>
          </div>
          <Divider />

          {/* User Addresses */}
          {userAddresses?.length > 0 ? (
            <div>
              {userAddresses.map((address) => (
                <Card key={address.id} style={{ marginBottom: "10px" }}>
                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <div>
                        <span style={{ marginLeft: "5px", fontWeight: "bold" }}>
                          {address.name}
                        </span>
                      </div>

                      {address.type === "both" ? (
                        <div>
                          <Tag className="ml-3">Shipping</Tag>{" "}
                          <Tag className="">Billing</Tag>
                        </div>
                      ) : (
                        <Tag className="ml-3">{address.type}</Tag>
                      )}
                    </div>

                    <p
                      className="text-danger m-0"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDeleteAddress(address.id)}
                    >
                      {isMobile ? <DeleteOutlined /> : "Delete"}
                    </p>
                  </div>

                  <div>{`${address.line_1} ${
                    address.line_2 ? `, ${address.line_2}` : ""
                  }`}</div>

                  <div className="mb-2">
                    {` ${address.city}, ${address.state}`},{" "}
                    {`${address.country},  ${address.postcode}`}
                  </div>

                  <div className="mb-2">{`Mobile: ${address.mobile}`}</div>
                  {address.landmark && (
                    <div>{`Landmark: ${address.landmark}`}</div>
                  )}
                </Card>
              ))}
            </div>
          ) : (
            <div style={{ height: "20vh" }}>
              <p>No addresses found</p>
            </div>
          )}
        </div>
        {/* Edit Modal */}
        <Modal
          open={open}
          title={`Edit ${editField}`}
          onCancel={handleCancel}
          maskClosable={false} // Prevent closing when clicking outside the modal
          footer={[
            <Button
              key="cancel"
              onClick={handleCancel}
              className="secondary-btn w-25"
            >
              Cancel
            </Button>,
            <Button
              key="save"
              className="primary-btn w-25"
              onClick={handleSave}
              disabled={!inputValue || isSaveDisabled}
            >
              Save
            </Button>,
          ]}
        >
          <Form form={form}>
            {editField === "password" ? (
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters long!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Enter Passwords"
                  className="text-start primary-input"
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    handleFieldsChange();
                  }}
                />
              </Form.Item>
            ) : inputValue2 ? (
              <>
                <Input
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder={`Enter ${editField}`}
                  className="mb-3 mt-3 primary-input"
                />
                <Input
                  value={inputValue2}
                  onChange={(e) => setInputValue2(e.target.value)}
                  placeholder={`Enter ${editField}`}
                  className="primary-input"
                />
              </>
            ) : (
              <Input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder={`Enter ${editField}`}
                className=" primary-input"
              />
            )}
          </Form>
        </Modal>
        <Modal
          open={addAddressOpen}
          title="Add Address"
          onCancel={handleCancel}
          maskClosable={false} // Prevent closing when clicking outside the modal
          footer={[]}
        >
          <AddressFormComponent setOpen={setAddAddressOpen} />
        </Modal>
      </div>

      <FooterComponent />
    </div>
  );
};

export default MyDetails;
