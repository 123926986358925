import { useState } from "react";
import AxiosInstance from "../Config/axios";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";

const CheckoutComponent = ({
  price,
  text,
  currency,
  subject,
  description,
  addressId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showText, setShowText] = useState(false);
  const handleBuyNow = async () => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance.post(
        "/create-checkout-session",
        { price, currency, subject, description, addressId },

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Handle the response as needed

      // Redirect to Stripe Checkout page on success
      window.location.href = response.data.url; // Assuming the response contains the URL
    } catch (error) {
      // Handle errors
      console.error("API request failed:", error);
    }
  };
  return (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          // handleBuyNow()
          setShowText(true);
        }}
        className="primary-btn mb-3  "
      >
        {isLoading ? (
          <div>
            {text} <LoadingOutlined />
          </div>
        ) : (
          text
        )}
      </Button>
      {showText && (
        <p className="text-danger">
          The gateway servers are down, please try again later
        </p>
      )}
    </>
  );
};

export default CheckoutComponent;
