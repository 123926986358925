import React from "react";
import NavBar from "../Layout/nav-bar";

const ShippingPage = () => {
  return (
    <div>
      <NavBar />

      <div></div>
    </div>
  );
};

export default ShippingPage;
