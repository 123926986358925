import React, { useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import AxiosInstance from "../../Config/axios";
import NavBar from "../../Layout/nav-bar";
import { contact_api, domain } from "../../Config/config";
import emailIcon from "../../Icons/email.gif";
import { Helmet } from "react-helmet";
import FooterComponent from "../../Layout/footer";
import { MetaTags } from "../../Utils/helperFunction";

interface ContactComponentProps {
  alignment: string;
}

const Contact = () => {
  return (
    <div>
      <MetaTags
        title="Risheet Contact"
        description=""
        robots="index, nofollow"
        schema={null}
      />

      <NavBar />
      <div className="d-lg-flex">
        <div className="main-container main-container-height  text-left col-lg-6">
          <h1 className=" page-header">GET IN TOUCH</h1>
          <p>
            I love to draw vibrant and exciting things. I am open to all kinds
            of projects, collaborations or to license my artwork with various
            brands. Contact me here to discuss making something awesome.
          </p>

          <ContactComponent alignment="left" />
        </div>
        <div
          className="col-lg-6 d-none d-lg-block"
          style={{
            backgroundImage:
              "url(https://res.cloudinary.com/dsw1ubwyh/image/upload/v1713980814/BLACK_and_White_DOODLE_1_lfu8tv.jpg)",

            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>

      <FooterComponent />
    </div>
  );
};

export default Contact;

export const ContactComponent: React.FC<ContactComponentProps> = ({
  alignment,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const handleSubmit = (values: any) => {
    setIsLoading(true);

    AxiosInstance.post(contact_api, values, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        //eslint-disable-next-line
        const data = await response.data;

        setIsLoading(false);

        setModalVisible(true);

        form.resetFields(); // Resetting the form fields
      })
      .catch((err) => {
        alert("Message sending Failed");
        console.log("error", err);
      });
  };
  const handleModalClose = () => {
    setModalVisible(false);
  };
  return (
    <div>
      <section id="contact" className="contact  p-0">
        <div className="">
          <div className="row ">
            <div className="col-lg-12">
              <Form form={form} onFinish={handleSubmit}>
                <div className="row">
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter at least 4 characters",
                        min: 4,
                      },
                    ]}
                    className="col-lg-6"
                  >
                    <Input className="primary-input " placeholder="Name" />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a valid email",
                        type: "email",
                      },
                    ]}
                    className="col-lg-6"
                  >
                    <Input className="primary-input" placeholder="Email" />
                  </Form.Item>
                </div>

                <Form.Item
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Please write something for us",
                    },
                  ]}
                >
                  <Input.TextArea
                    className="primary-textarea"
                    style={{ borderRadius: "0" }}
                    rows={5}
                    placeholder="Message"
                  />
                </Form.Item>
                <Form.Item className={`text-${alignment}`}>
                  <Button
                    htmlType="submit"
                    disabled={isLoading}
                    className="primary-btn mt-3"
                  >
                    {isLoading ? <LoadingOutlined /> : "Send Message"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </section>
      <Modal
        visible={modalVisible}
        footer={null}
        centered
        onCancel={handleModalClose}
      >
        <div className="container p-3 d-flex-column align-items-center text-center">
          <img width={80} src={emailIcon} alt="" loading="lazy" />
          <p className="">
            Thanks for reaching out. Your message has been sent to my parents.
            We will get back to you soon.
          </p>
        </div>
      </Modal>
    </div>
  );
};
