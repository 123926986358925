import React, { useState } from "react";
import NavBar from "../../Layout/nav-bar";
import FooterComponent from "../../Layout/footer";
import { Button, Form, Input, message } from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router";
import AxiosInstance from "../../Config/axios";
import {
  domain,
  home_path,
  login_api,
  register_path,
} from "../../Config/config";
import { useApplicationContext } from "../../Context/app-context";
import { Helmet } from "react-helmet";
import { MetaTags } from "../../Utils/helperFunction";

const Login = () => {
  const [form] = Form.useForm();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [credentialsErr, setCredentialsErr] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();
  const { setUserData } = useApplicationContext();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);

    const formData = new FormData();

    formData.append("email", email);
    formData.append("password", password);

    try {
      const response = await AxiosInstance.post(login_api, formData, {
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.data;

      setIsLoading(false);

      if (data.status === 404) {
        error(data.message);
        setCredentialsErr(true);
        return;
      }

      const accessToken = data.accessToken;

      if (!accessToken) return error(data);

      localStorage.setItem("accessToken", accessToken);
      setUserData(data);
      if (location.state && location.state.from) {
        navigate(location.state.from.pathname + location.state.from.search);
      } else {
        // If there's no state, navigate to the home page or any default page
        navigate(home_path);
      }

      setEmail("");
      setPassword("");
    } catch (err) {
      error("Something Wrong");
      console.log(err);
    }
  };
  const error = (data: string) => {
    messageApi.open({
      type: "error",
      content: data,
    });
  };

  return (
    <>
      <MetaTags
        title="Risheet Login"
        description=""
        robots="index, nofollow"
        schema={null}
      />

      <NavBar />
      {contextHolder}
      <div className="d-lg-flex">
        <div className=" text-left main-container main-container-height col-lg-6">
          <h1 className=" page-header">CUSTOMER LOGIN</h1>
          <div className=" p-0">
            <Form form={form} onFinish={handleSubmit}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid email",
                    type: "email",
                  },
                ]}
                validateStatus={credentialsErr ? "error" : ""}
                help={credentialsErr ? "Invalid credentials" : ""}
              >
                <Input
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="text-start primary-input"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  className="text-start primary-input"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>

              <div className="d-flex justify-content-end mb-4">
                <a className="text-dark" href="/forgot-password">
                  Forgot Password?
                </a>
              </div>

              <div className="text-left">
                <Button
                  htmlType="submit"
                  style={{ transition: "all ease 1s" }}
                  disabled={!(email && password)}
                  className="primary-btn"
                >
                  {isLoading ? (
                    <span>
                      Log in... <LoadingOutlined />
                    </span>
                  ) : (
                    "Log in"
                  )}
                </Button>
                <p className="card-text text-muted mt-3">
                  Don't have an account yet?
                  <span
                    className="text-dark"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(register_path)}
                  >
                    {" "}
                    Sign up here
                  </span>
                </p>
              </div>
            </Form>
          </div>
        </div>
        <div
          className="col-lg-6 d-none d-lg-block"
          style={{
            backgroundImage:
              "url(https://res.cloudinary.com/dsw1ubwyh/image/upload/v1713980814/BLACK_and_White_DOODLE_1_lfu8tv.jpg)",

            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>

      <FooterComponent />
    </>
  );
};

export default Login;
