import React from "react";
import { home_path } from "../Config/config";
import logo from "../logo.png";
import { useNavigate } from "react-router-dom";
import {
  InstagramOutlined,
  LinkedinOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import FreeShipping from "./free-shipping";
import linktree from "../Images/linktree.png";
const FooterComponent = () => {
  const navigate = useNavigate();
  return (
    <>
      <footer id="footer">
        <div className="container py-3 footer-container ">
          <div>
            <img
              onClick={() => navigate(home_path)}
              width={100}
              height={50}
              id="logo"
              src={logo}
              alt="risheet logo"
              loading="lazy"
            />
          </div>
          <div className="copy-right">
            <p className="mb-2" style={{ fontSize: "18px" }}>
              © 2024
            </p>
          </div>

          <div style={{ display: "grid", placeItems: "center" }}>
            <div className="social-links  text-center pt-md-0 ">
              <a
                href="https://www.instagram.com/risheetmazumdar/"
                className="instagram"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramOutlined />
              </a>
              <a
                href="https://www.youtube.com/channel/UC5Yg2UE5coog2AunnASmJRg"
                className="youtube"
                target="_blank"
                rel="noreferrer"
              >
                <YoutubeOutlined />
              </a>

              <a
                href="https://www.linkedin.com/in/risheet-mazumdar/"
                className="instagram"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedinOutlined />
              </a>

              <a
                href="https://linktr.ee/risheet"
                className="linktree"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={linktree}
                  alt="linktree"
                  loading="lazy"
                  width={22.32}
                  height={23.32}
                  className="linktree "
                  style={{ paddingBottom: "4px" }}
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
      <FreeShipping />
    </>
  );
};

export default FooterComponent;
