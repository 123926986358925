import React from "react";
import NavBar from "../../Layout/nav-bar";
import FooterComponent from "../../Layout/footer";
import { useApplicationContext } from "../../Context/app-context";
import {
  add_to_cart_api,
  domain,
  login_path,
  product_collection_path,
  product_path,
  remove_wishlist_item_api,
} from "../../Config/config";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { ArrowRightOutlined, CloseOutlined } from "@ant-design/icons";
import AxiosInstance from "../../Config/axios";
import { Helmet } from "react-helmet";
import { MetaTags } from "../../Utils/helperFunction";

const Wishlist = () => {
  const {
    wishlistItems,
    currencySymbol,
    getConvertedPrice,
    userData,
    getWishlistItems,
    getCartItems,
    allProducts,
  } = useApplicationContext();
  const accessToken = localStorage.getItem("accessToken");

  const navigate = useNavigate();

  const getStockQuantity = (id: number) => {
    const filter = allProducts.filter((product) => product.id === id);
    return filter[0].stock;
  };

  const handleMoveToCart = (id: number) => {
    const formData = new FormData();
    formData.append("product_id", id.toString());
    formData.append("quantity", "1");
    AxiosInstance.post(add_to_cart_api, formData, {
      headers: {
        "Content-Type": "application/json",
        token: `Bearer ${accessToken}`,
      },
    })
      .then(async (res) => {
        const response = await res.data;
        if (response.status !== 200) {
          navigate(login_path);
          return;
        }

        handleRemove(id);
        getWishlistItems();
      })
      .catch((err) => console.log(err));
  };

  const handleRemove = (productID: number) => {
    AxiosInstance.post(
      remove_wishlist_item_api,
      { product_id: productID },
      {
        headers: {
          "Content-Type": "application/json",
          token: `Bearer ${accessToken}`,
        },
      }
    )
      .then(async (res) => {
        const response = await res.data;
        if (response.status !== 200) {
          navigate(login_path);
        }

        getWishlistItems();
        getCartItems();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <MetaTags
        title="Risheet Wishlist"
        description=""
        robots="noindex, nofollow"
        schema={null}
      />

      <NavBar />
      <div className=" text-left main-container main-container-height">
        <p className=" page-header">Wishlist</p>
        {userData ? (
          <div>
            {wishlistItems?.length > 0 ? (
              <div className="shop-products-container ">
                {wishlistItems.map((product, index) => (
                  <div
                    style={{ position: "relative" }}
                    className="shop-product-card border"
                  >
                    <div
                      key={index}
                      onClick={() => {
                        sessionStorage.setItem(
                          "id",
                          product.product_id.toString()
                        );
                        navigate(
                          `${product_collection_path}/${product.transformed_name}`
                        );
                      }}
                    >
                      <div className="square-image-container">
                        <img
                          width={50}
                          src={product.imageURLs[0]}
                          alt={product.name}
                          loading="lazy"
                          className="square-image"
                        />

                        {/* {getStockQuantity(product.product_id) === 0 && (
                          <div className="sold-out-overlay">
                            <p>Sold Out</p>
                          </div>
                        )} */}
                      </div>

                      <div className="text-left px-2 pt-1">
                        <p className="mt-3 thumbnail-name ">{product.name}</p>
                        <p className="thumbnail-price d-none">
                          {currencySymbol} {getConvertedPrice(product.price)}
                        </p>
                      </div>
                    </div>
                    {getStockQuantity(product.product_id) === 0 ? (
                      <p className="text-danger ml-1 mb-3">Out of stock</p>
                    ) : (
                      <Button
                        className="primary-btn mx-2 mb-3 "
                        onClick={() => handleMoveToCart(product.product_id)}
                      >
                        Move to cart
                      </Button>
                    )}

                    <div
                      className="removeIcon"
                      onClick={() => handleRemove(product.product_id)}
                    >
                      <CloseOutlined />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div style={{ height: "30vh" }}>
                <p>You haven't add any products yet</p>
              </div>
            )}
          </div>
        ) : (
          <div style={{ height: "30vh" }}>
            <p>
              Please login to view items in your wishlist.{" "}
              <span
                onClick={() => navigate(login_path)}
                className="color-primary"
                style={{ cursor: "pointer" }}
              >
                {" "}
                Login <ArrowRightOutlined />
              </span>
            </p>
          </div>
        )}
      </div>

      <FooterComponent />
    </div>
  );
};

export default Wishlist;
